import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import HiraginoOtf from '../assets/fonts/Hiragino.otf';
import breakpoints from './breakpoints';
import palette from './palette';
import typography from './typography';

let theme = createTheme({
  palette,
  typography,
  breakpoints,

  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Hiragino';
          font-style: normal;
          src: url(${HiraginoOtf}) format('opentype');
        }
      `,
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
