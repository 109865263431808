import api from 'utils/api';

const getReportSettings = async () => {
  const { data } = await api.get('/report-settings');

  return data;
};

const updateReportSettings = async (params) => {
  const { data } = await api.patch('/report-settings', params);

  return data;
};

export { getReportSettings, updateReportSettings };
