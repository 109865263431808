import { createSlice } from '@reduxjs/toolkit';
import { updateReportSettings } from './actions';

const initialState = {
  data: undefined,
  fiscalCalendar: undefined,
  isLoading: false,
  error: '',
  success: '',
};

const reportSettings = createSlice({
  name: 'reportSettings',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setReportSettingsData: (state, action) => {
      state.data = action.payload;
    },
    setFiscalCalendar: (state, action) => {
      state.fiscalCalendar = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateReportSettings.pending, (state) => {
      state.isLoading = true;
      state.error = '';
      state.success = '';
    });
    builder.addCase(updateReportSettings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data || undefined;
      state.success = action.payload.success || '';

      if (action?.payload?.fiscalCalendar) {
        const fiscalCalendar = action.payload.fiscalCalendar;

        state.fiscalCalendar = {
          start: fiscalCalendar.start,
          end: fiscalCalendar.end,
        };
      }
    });
    builder.addCase(updateReportSettings.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const actions = {
  ...reportSettings.actions,
  updateReportSettings,
};

export * as selectors from './selectors';

export default reportSettings.reducer;
