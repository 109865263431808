import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import * as rakutenService from 'services/rakuten.service';
import { isAxiosError } from 'utils/api';
import { actions } from './';
import { getRakutenImportProductError } from './utils';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const getColumnName = (columnNumber) => {
  var temp,
    letter = '';
  while (columnNumber > 0) {
    temp = (columnNumber - 1) % 26;
    letter = String.fromCharCode(temp + 65) + letter;
    columnNumber = (columnNumber - temp - 1) / 26;
  }
  return letter;
};

export const importRakutenProductViews = createAsyncThunk(
  'rakuten_import/import_product_views',
  async (payload, thunkAPI) => {
    try {
      const { data } = await rakutenService.importProductViews(payload, async (upload) => {
        thunkAPI.dispatch(actions.setUploadProgress(10));

        await delay(500);

        let progress = Math.round((100 * upload.loaded) / upload.total);

        thunkAPI.dispatch(actions.setUploadProgress(progress));

        // clear progress after 100%
        if (progress === 100) {
          await delay(500);

          thunkAPI.dispatch(actions.setUploadProgress(0));
        }
      });

      const message = i18next.t('pages.rakuten_import.import_success', {
        rows: data?.importedRows || 0,
      });
      const failedRows = data?.failures?.map((failure) => {
        const column = getColumnName(parseInt(failure.attribute) + 1);
        const error = failure?.errors?.[0];
        const message =
          error === 'not_exists'
            ? i18next.t('pages.rakuten_import.column_not_exists')
            : i18next.t('pages.rakuten_import.column_empty', { column });

        return i18next.t('pages.rakuten_import.failed_row', { row: failure.row, error: message });
      });

      return thunkAPI.fulfillWithValue({ message, failedRows });
    } catch (error) {
      let errorMessage = error?.message;

      if (isAxiosError(error)) {
        errorMessage = getRakutenImportProductError(error);
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);

export const importProducts = createAsyncThunk(
  'rakuten_import/import_products',
  async (payload, thunkAPI) => {
    try {
      const { data } = await rakutenService.importProducts(payload, async (upload) => {
        thunkAPI.dispatch(actions.setUploadProgress(10));

        await delay(500);

        let progress = Math.round((100 * upload.loaded) / upload.total);

        thunkAPI.dispatch(actions.setUploadProgress(progress));

        // clear progress after 100%
        if (progress === 100) {
          await delay(500);

          thunkAPI.dispatch(actions.setUploadProgress(0));
        }
      });

      const message = i18next.t('pages.rakuten_import.import_success', {
        rows: data?.importedRows || 0,
      });
      const failedRows = data?.failures?.map((failure) => {
        const column = getColumnName(parseInt(failure.attribute) + 1);
        const message = i18next.t('pages.rakuten_import.column_empty', { column });

        return i18next.t('pages.rakuten_import.failed_row', { row: failure.row, error: message });
      });

      return thunkAPI.fulfillWithValue({ message, failedRows });
    } catch (error) {
      let errorMessage = error?.message;

      if (isAxiosError(error)) {
        errorMessage = getRakutenImportProductError(error);
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);
