import { createAsyncThunk } from '@reduxjs/toolkit';
import { ECChannels } from 'constants/ecChannels';
import i18next from 'i18next';
import { selectors as profileSelectors } from 'modules/profile';
import * as rakutenService from 'services/rakuten.service';
import * as storeService from 'services/store.service';
import { isAxiosError } from 'utils/api';
import { generateAmazonAuthUrl, generateShopifyAuthUrl, getRakutenLinkingError } from './utils';

export const linkChannel = createAsyncThunk('channel/link', async (payload, thunkAPI) => {
  const { channel, data } = payload;
  const rootState = thunkAPI.getState();
  const profileId = profileSelectors.idSelector(rootState);
  let result = '';

  // Rakuten
  if (channel === ECChannels.RAKUTEN) {
    try {
      await rakutenService.link(data);

      result = i18next.t('pages.ec_settings.rakuten.link_success');
    } catch (error) {
      let errorMessage = error?.message;

      if (isAxiosError(error)) {
        errorMessage = getRakutenLinkingError(error);
      }

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }

  // Shopify
  if (channel === ECChannels.SHOPIFY) {
    result = generateShopifyAuthUrl(data.shopUrl, profileId);
  }

  // Amazon
  if (channel === ECChannels.AMAZON) {
    result = generateAmazonAuthUrl(data.amazonUrl, profileId);
  }

  return thunkAPI.fulfillWithValue(result);
});

export const getStores = createAsyncThunk('channel/get_stores', async (_, thunkAPI) => {
  try {
    const { data } = await storeService.getStores();

    return thunkAPI.fulfillWithValue(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.message);
  }
});

// Update store
export const updateStore = createAsyncThunk('channel/update', async (payload, thunkAPI) => {
  try {
    const { data } = await storeService.updateStore(payload);
    return thunkAPI.fulfillWithValue(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.message);
  }
});

export const deleteStore = createAsyncThunk('channel/delete', async (payload, thunkAPI) => {
  try {
    const { data } = await storeService.deleteStore(payload);
    return thunkAPI.fulfillWithValue(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.message);
  }
});
