import { createSlice } from '@reduxjs/toolkit';
import {
  deleteProductGroup,
  fetchExportData,
  fetchProductGroup,
  fetchProductMaster,
  importProductGroup,
} from './actions';

const initialState = {
  isLoading: false,
  productGroupUploadProgress: 0,
  success: '',
  list: [],
  error: '',
  errorImport: '',
  failedRows: [],
  exportedData: '',
  masterList: '',
};

const productGroupSlice = createSlice({
  name: 'productGrup',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Fect product group data list
    builder.addCase(fetchProductGroup.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProductGroup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload.list;
      state.meta = action.payload.meta;
    });
    builder.addCase(fetchProductGroup.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // Fect product master data
    builder.addCase(fetchProductMaster.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProductMaster.fulfilled, (state, action) => {
      state.isLoading = false;
      state.masterList = action.payload;
    });
    builder.addCase(fetchProductMaster.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // Fect exported product group data list
    builder.addCase(fetchExportData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchExportData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.exportedData = action.payload;
    });
    builder.addCase(fetchExportData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // Import
    builder.addCase(importProductGroup.pending, (state) => {
      state.isLoading = true;
      state.success = '';
    });
    builder.addCase(importProductGroup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    });
    builder.addCase(importProductGroup.rejected, (state, action) => {
      state.isLoading = false;
      state.errorImport = action.payload;
    });

    builder.addCase(deleteProductGroup.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(deleteProductGroup.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.success = action.payload;
    });
    builder.addCase(deleteProductGroup.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default productGroupSlice.reducer;

export const actions = {
  ...productGroupSlice.actions,
  fetchProductGroup,
  importProductGroup,
  fetchExportData,
  fetchProductMaster,
  deleteProductGroup,
};

export * as selectors from './selectors';
