import { combineReducers, configureStore } from '@reduxjs/toolkit';
import AccountReducer from './account';
import AnalysisByChannelReducer from './analysis_by_channel';
import AnalysisByProductReducer from './analysis_by_product';
import AppReducer from './app';
import AuthReducer from './auth';
import BudgetReducer from './budget';
import DashboardReducer from './dashboard';
import ChannelReducer from './ec_channel';
import ProductGroupReducer from './product_group';
import ProductMasterReducer from './product_master';
import ProfileReducer from './profile';
import RakutenImportReducer from './rakuten_import';
import ReportSettingsReducer from './report_settings';
import UserReducer from './user';

const rootReducer = combineReducers({
  analysisByChannel: AnalysisByChannelReducer,
  analysisByProduct: AnalysisByProductReducer,
  app: AppReducer,
  auth: AuthReducer,
  ecChannel: ChannelReducer,
  dashboard: DashboardReducer,
  profile: ProfileReducer,
  productGroup: ProductGroupReducer,
  productMaster: ProductMasterReducer,
  rakutenImport: RakutenImportReducer,
  reportSettings: ReportSettingsReducer,
  user: UserReducer,
  budget: BudgetReducer,
  account: AccountReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
