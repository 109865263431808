import { createSelector } from '@reduxjs/toolkit';

const rootSelector = createSelector(
  (state) => state.productGroup,
  (productGroup) => productGroup
);

export const listSelector = createSelector(rootSelector, (productGroup) => productGroup.list);

export const metaSelector = createSelector(rootSelector, (productGroup) => productGroup.meta);

export const errorSelector = createSelector(rootSelector, (productGroup) => productGroup.error);

export const loadingSelector = createSelector(
  rootSelector,
  (productGroup) => productGroup.isLoading
);
