import { createSelector } from '@reduxjs/toolkit';

const rootSelector = createSelector(
  (state) => state.dashboard,
  (dashboard) => dashboard
);

export const availableDatesSelector = createSelector(
  rootSelector,
  (dashboard) => dashboard.availableDates
);

export const dailyPerformanceSelector = createSelector(
  rootSelector,
  (dashboard) => dashboard.dailyPerformance
);

export const weeklyPerformanceSelector = createSelector(
  rootSelector,
  (dashboard) => dashboard.weeklyPerformance
);

export const monthlyPerformanceSelector = createSelector(
  rootSelector,
  (dashboard) => dashboard.monthlyPerformance
);

export const performanceGraphSelector = createSelector(
  rootSelector,
  (dashboard) => dashboard.performanceGraph
);

export const topSalesProductsSelector = createSelector(
  rootSelector,
  (dashboard) => dashboard.topSalesProducts
);

export const topTrendingProductsSelector = createSelector(
  rootSelector,
  (dashboard) => dashboard.topTrendingProducts
);

export const topWorstProductsSelector = createSelector(
  rootSelector,
  (dashboard) => dashboard.topWorstProducts
);
