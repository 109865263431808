/**
 * Identify a given param wether it's a number or not.
 *
 * @example
 * // isNumeric(12345678912345678912) // true
 * // isNumeric('123') // true
 * // isNumeric('-32.2 ') // true
 * // isNumeric(undefined) // false
 *
 * @param {string|number} str
 * @return {boolean}
 */
export const isNumeric = (value) => {
  return !isNaN(parseFloat(value)) && isFinite(value);
};

export default isNumeric;
