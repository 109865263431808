import { createSelector } from '@reduxjs/toolkit';

const rootSelector = createSelector(
  (state) => state.productMaster,
  (productMaster) => productMaster
);

export const exportErrorResponseSelector = createSelector(
  rootSelector,
  (productMaster) => productMaster.exportErrorResponse
);
export const isErrorImportSelector = createSelector(
  rootSelector,
  (productMaster) => productMaster.isErrorImport
);
export const importResponseSelector = createSelector(
  rootSelector,
  (productMaster) => productMaster.importResponse
);
export const exportDataResponseSelector = createSelector(
  rootSelector,
  (productMaster) => productMaster.exportDataResponse
);
export const fetchProductMasterErrorSelector = createSelector(
  rootSelector,
  (productMaster) => productMaster.fetchProductMasterError
);
export const fetchProductMasterListSelector = createSelector(
  rootSelector,
  (productMaster) => productMaster.fetchProductMasterList
);
export const listSelector = createSelector(rootSelector, (productMaster) => productMaster.list);
export const isLoadingSelector = createSelector(
  rootSelector,
  (productMaster) => productMaster.isLoading
);
export const paginationMetaSelector = createSelector(
  rootSelector,
  (productMaster) => productMaster.meta
);
export const errorSelector = createSelector(rootSelector, (productMaster) => productMaster.error);
