import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import * as productMasterService from 'services/product.master.service';

export const fetchProductMaster = createAsyncThunk(
  'product_master/fetchProductMaster',
  async (params, thunkAPI) => {
    try {
      const { data, meta } = await productMasterService.fetchProductMaster(params);
      return thunkAPI.fulfillWithValue({ list: data, meta });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const isLinkChannel = createAsyncThunk('product_master/isLink', async (_, thunkAPI) => {
  try {
    const result = await productMasterService.isLinkChannel();
    return thunkAPI.fulfillWithValue(result?.data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.message);
  }
});

export const exportData = createAsyncThunk('product_master/export', async (_, thunkAPI) => {
  try {
    const result = await productMasterService.exportData();
    return thunkAPI.fulfillWithValue(result?.data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.message);
  }
});

export const deleteProductMasterList = createAsyncThunk(
  'product_master/delete',
  async (payload, thunkAPI) => {
    try {
      const result = await productMasterService.deleteProductMasterList(payload);
      return thunkAPI.fulfillWithValue(result?.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const importData = createAsyncThunk('product_master/import', async (payload, thunkAPI) => {
  try {
    await productMasterService.importData(payload);
    const result = i18next.t('pages.product_master.excel_button.success_import');

    return thunkAPI.fulfillWithValue(result?.data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data?.error);
  }
});

export const resetLink = createAsyncThunk('product_master/resetLink', async (_, thunkAPI) => {
  try {
    return thunkAPI.fulfillWithValue();
  } catch (error) {
    return thunkAPI.rejectWithValue();
  }
});
