import api from 'utils/api';

export const link = async (data) => api.post('/rakuten/auth', data);

export const importProducts = async (payload, onUploadProgress) => {
  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('user_channel_id', payload.selectedStore);

  return api.post('/rakuten/products/import', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};

export const importProductViews = async (payload, onUploadProgress) => {
  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('user_channel_id', payload.selectedStore);

  return api.post('/rakuten/product-views/import', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  });
};
