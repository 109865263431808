import { createSelector } from '@reduxjs/toolkit';

const rootSelector = createSelector(
  (state) => state.ecChannel,
  (ecChannel) => ecChannel
);

export const isLoadingSelector = createSelector(rootSelector, (ecChannel) => ecChannel.isLoading);

export const isLinkingLoadingSelector = createSelector(
  rootSelector,
  (ecChannel) => ecChannel.isLinkingLoading
);

export const isUploadProductsLoading = createSelector(
  rootSelector,
  (ecChannel) => ecChannel.isUploadProductsLoading
);

export const errorSelector = createSelector(rootSelector, (ecChannel) => ecChannel.error);

export const successSelector = createSelector(rootSelector, (ecChannel) => ecChannel.success);

export const storesSelector = createSelector(rootSelector, (ecChannel) => ecChannel.stores);
