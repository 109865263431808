import { createSelector } from '@reduxjs/toolkit';

const rootSelector = createSelector(
  (state) => state.profile,
  (profile) => profile
);

export const dataSelector = createSelector(rootSelector, (profile) => profile.data);

export const roleSelector = createSelector(dataSelector, (data) => data?.role);

export const idSelector = createSelector(dataSelector, (data) => data?.id);
