import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: undefined,
  isLoading: false,
  error: '',
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfileData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const actions = {
  ...profileSlice.actions,
};

export * as selectors from './selectors';

export default profileSlice.reducer;
