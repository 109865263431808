import { createSelector } from '@reduxjs/toolkit';

const rootSelector = createSelector(
  (state) => state.rakutenImport,
  (rakutenImport) => rakutenImport
);

export const isLoadingSelector = createSelector(
  rootSelector,
  (rakutenImport) => rakutenImport.isLoading
);

export const isUploadProductsLoading = createSelector(
  rootSelector,
  (rakutenImport) => rakutenImport.isUploadProductsLoading
);

export const errorSelector = createSelector(rootSelector, (rakutenImport) => rakutenImport.error);

export const successSelector = createSelector(
  rootSelector,
  (rakutenImport) => rakutenImport.success
);

export const storesSelector = createSelector(rootSelector, (rakutenImport) => rakutenImport.stores);

export const uploadProgress = createSelector(
  rootSelector,
  (rakutenImport) => rakutenImport.uploadProgress
);

export const failedRowsSelector = createSelector(
  rootSelector,
  (rakutenImport) => rakutenImport.failedRows
);
