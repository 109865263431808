import PropTypes from 'prop-types';
import { toast as showToast } from 'react-toastify';

const ErrorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-exclamation-circle"
    viewBox="0 0 16 16"
  >
    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
  </svg>
);

const Toast = ({
  message,
  type,
  position,
  style,
  hideProgressBar,
  timer = 5000,
  toastId = null,
}) => {
  const toastProps = {
    type: type,
    position: position || 'top-center',
    style: {
      width: '500px',
      wordWrap: 'break-word',
      backgroundColor: type === 'success' ? '#B8F2B7' : '#E74C3C',
      color: type === 'success' ? 'black' : 'white',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      ...style,
    },
    hideProgressBar: hideProgressBar === undefined ? true : hideProgressBar,
    autoClose: timer,
    icon: type === 'error' ? <ErrorIcon /> : null,
    toastId: toastId,
  };

  return showToast(<div dangerouslySetInnerHTML={{ __html: message }} />, toastProps);
};

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
  position: PropTypes.string,
  style: PropTypes.object,
  hideProgressBar: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  timer: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  toastId: PropTypes.string,
};

export default Toast;
