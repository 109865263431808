const en = {
  translation: {
    // define translations below
    form: {
      required: 'This field is required.',
      email: {
        required: 'Please enter your email。',
        invalid: 'Invalid email address.',
        invalid_format: 'Invalid email address.',
        not_found: 'Email address not found.',
        already_exist: 'Email address already exists.',
        invalid_length: 'Please enter between 5 and 50 characters.',
        special_characters: 'They contain unavailable characters.',
      },
      password: {
        minLength: 'Please enter between 8 and 25 characters.',
        maxLength: 'Please enter between 8 and 25 characters.',
        noSpace: 'Do not enter spaces.',
        confirm: 'Password confirmation does not match.',
        strong:
          'Password must contain the following: 1 uppercase, 1 special character and a minimum of 8 characters.',
      },
      username: {
        minLength: 'Please enter between 5 and 20 characters.',
        maxLength: 'Please enter between 5 and 20 characters.',
        noSpace: 'Do not enter spaces.',
        alpha_only: 'Please do not use special characters.',
        alphanumeric: 'Please enter alphanumeric characters.',
        required: 'ID is required.',
        already_exist: 'ID already exists.',
      },
      shopUrl: {
        invalid: 'Please enter a valid myshopify.com domain',
      },
      firstName: {
        required: 'Please enter your first name.',
        minLength: 'Please enter between 5 and 20 characters.',
        maxLength: 'Please enter between 5 and 20 characters.',
      },
      lastName: {
        required: 'Please enter your last name.',
        minLength: 'Please enter between 5 and 20 characters.',
        maxLength: 'Please enter between 5 and 20 characters.',
      },
      network_error: 'Network Error',
    },
    labels: {
      app: 'Cross Analytics',
      first_name: 'First name.',
      first_name_placeholder: 'Please enter your first name.',
      last_name: 'Last name.',
      last_name_placeholder: 'Please enter your last name.',
      login: 'Login',
      signup: 'Signup',
      remember_me: 'Remember Me',
      forgot_password:
        'If you have forgotten your password, please click <Link>here</Link> to reset it.',
      email_address: 'Email Address',
      username: 'ID',
      username_placeholder: 'Please enter your ID',
      confirm_password: 'New Password (confirm)',
      update_password: 'Update Password',
      submit: 'Submit',
      register: 'Register',
      update: 'Update',
      save: 'Save',
      add_new: 'Add',
      reset_password: 'Reset Password',
      register_password: 'パスワード',
      new_password: 'New Password',
      confirm_new_password: 'Confirm New Password',
      enter_keyword: 'Enter Keyword',
      get_started: 'Get Started',
      integrations: 'Integrations',
      settings: 'Settings',
      documentation: 'Documentation',
      fullname: 'Fullname',
      inquiry_content: 'Inquiry Content',
      navigation: 'Navigation',
      resources: 'Resources',
      cancel: 'Cancel',
      action: 'Action',
      close: 'Close',
      admin: 'Admin',
      superadmin: 'Superadmin',
      standard: 'Standard',
      back: 'Back',
      user_type: 'User Type',
      select: 'Please make a selection.',
      ok: 'OK',
      success: 'success',
      error: 'error',
      usage_heading: 'Usage',
      all_channels: 'All Channels',
      ec: 'EC Store',
      shopify: 'Shopify',
      rakuten: 'Rakuten',
      amazon: 'Amazon',
      yahoo: 'Yahoo!',
      help: 'Help',
      login_form: {
        username: 'Please enter your ID',
        password: 'Please enter your Password',
      },
      delete_confirmation_modal: {
        submit: 'Yes',
      },
      select_product_group: 'Select Product Group',
      select_month: 'Select Month',
      select_week: 'Select Week',

      select_date_aggregation: 'Select Date Aggregation',
      select_month_aggregation: 'Select Month Aggregation',
      select_week_aggregation: 'Select Week Aggregation',

      yesterday: 'Yesterday',
      last_week: 'Last Week',
      last_month: 'Last Month',
      last_year: 'Last Year',

      budget: 'Budget',
      sales_last_year: 'Sales Last Year',

      day: 'Day',
      week: 'Week',
      month: 'Month',
      year: 'Year',

      daily: 'Daily',
      weekly: 'Weekly',
      monthly: 'Monthly',

      // table components
      sales: 'Sales',
      sales_with_sign: 'Sales ({{sign}})',
      number_of_access: 'Number of Access',
      purchase_rate: 'Purchase Rate',
      order_unit_price: 'Order Unit Price',

      // list items
      volume: 'Volume',
      average_sales_per_unit: 'Average Sales per Unit',
      compared_yesterday: 'Compared to Yesterday',
      compared_last_week: 'Compared to Last Week',
      compared_last_month: 'Compared to Last Month',
      compared_last_year: 'Compared to Last Year',
      average_ratio: 'Average Ratio',

      search_products: 'Search Products',
      no_products: 'No Products',
    },
    table_columns: {
      product_name: 'Product Name',
      sales: 'Sales',
      sales_mix_ratio: 'Sales Mix Ratio',
      sales_mix_ratio_total: 'Sales Mix Ratio (Total)',
      sales_mix_ratio_channel: 'Sales Volume\n({{channel}})',
      number_of_views: 'Number of views',
      purchase_rate: 'Purchase Rate',
      compared_last_period: 'Compared to Last {{period}}({{sign}})',
      compared_last_month: 'Compared to Last Month ({{sign}})',
    },
    errors: {
      server: 'Something went wrong please try again later.',
      api_error: 'Please reload the page because an API error has occurred.',
      login: {
        user_locked:
          'Your account has been locked, please wait at least 60 minutes and try logging in again.',
        user_suspended: 'Account is suspended, please contact administrator for more information.',
        user_pending: 'Cannot login as email has not been verified yet.',
        invalid_user_credentials: 'ID or password is incorrect.',
        invalid_user_password: 'ID or password is incorrect.',
        failed_attempt:
          'Incorrect ID or password. If you fail to log in {{remainingAttempt}} more times, your account will be locked for 60 minutes.',
      },
      forgot_password: {
        user_not_found: 'We are unable to find the email address you entered.',
      },
      ecSettings: {
        shop_not_found: 'Shop not found.',
      },
      no_linked_store: 'There are no linked ecommerce site(s).',
    },
    pages: {
      signup: {
        agree_to_terms: 'By clicking Register, you agree that you have read and agree to the',
        signup_complete:
          'A confirmation email has been sent to your inbox. Click the link to complete the registration process.',
        terms_conditions: 'Terms & Conditions',
        create_free_account: 'Create your Free Account',
      },
      forgot_password: {
        title: 'Forgot Password',
        sub_title:
          'We will send you a link to the page to reset your password to the email address you entered.',
        sub_heading: 'To recover your account, please enter your email address below.',
        success_heading: 'We have sent you an email to reset your password.',
        success_sub_heading:
          'Please follow the instructions in the email we sent you to reset your password.',
      },
      reset_password: {
        sub_heading: 'Please enter your new password.',
        success: 'The password has been updated successfully.',
        error_msg: {
          incorrect_temp_pw: 'Temporary password is incorrect.',
          temp_pw_required: 'Please enter a temporary password.',
          new_pw_required: 'Please enter your new password.',
          confirm_pw_required: 'Re-enter new password is a required field.',
          confirm_pw_reenter: 'Re-entered password does not match.',
          pw_contains_whitespace: 'Do not include spaces.',
          pw_without_lowercase: 'Please input at least 1 uppercase letter.',
          pw_requirement_not_met: 'New Password does not met the requirements.',
        },
      },
      register_password: {
        card_title: 'Password',
        form: {
          temporary_password: 'Temporary password',
          temporary_password_placeholder: 'Please enter a temporary password.',
          error_message: {
            password_required: 'Please enter your new password.',
          },
        },
        submit_btn: 'Submit',
        modal: {
          update_pw_success: 'Password created',
          redirect_to_login: 'Go to login screen',
        },
      },
      users: {
        user_created: 'The user has been created.',
        user_updated: 'User details have been updated.',
        user_deleted: 'User has been deleted.',
        add_user: 'Add User',
        edit_user: 'Edit User',
        delete_user: 'Delete user',
        first_name: 'First Name',
        last_name: 'Last Name',
        email_address: 'Email Address',
        status: 'Status',
        delete_confirmation: 'Are you sure you want to delete the selected user?',
      },
      activate: {
        heading: 'Activate Account',
        subtitle: 'Set your password to activate your account.',
        activated: 'Your Account has been activated. You can now login to your account!',
      },
      dashboard: {
        title: 'Dashboard',
        periodic_performance: '{{period}} Performance',
        by_channel: 'By Channel',
        total_channels: 'Total of all channels',
        sales: 'Sales',
        budget_progress: 'Budget Progress',
        budget_ratio: 'Budget Ratio ({{sign}})',
        compared_last_week: 'Compared to Last Week',
        compared_last_month: 'Compared to Last Month ({{sign}})',
        compared_last_year: 'Compared to Last Year ({{sign}})',
        compared_budget: 'Compared to Budget ({{sign}})',

        trend: 'Trend',
        daily: 'Daily',
        weekly: 'Weekly',
        monthly: 'Monthly',
        aggregation: 'Aggregation of selected periods',
        elapsed_compared_budget: 'Number of days elapsed. Compared to budget ({{sign}})',
        elapsed_compared_last_year: 'Number of days elapsed. Compared to last year ({{sign}})',
        sales_top: 'Sales Top {{rankingCount}} Products',
        yesterday: 'Yesterday',
        last_week: 'Last Week',
        last_month: 'Last Month',
        last_year: 'Last Year',
        change_month: 'Change from Previous Month (%)',
        sales_composition: 'Sales Composition',

        budget: 'Budget',
        sales_last_year: 'Sales Last Year',
        product_name: 'Product Name',
        product_compared_last_month: 'Compared to Last Month({{sign}})',
        product_sales_mix_ratio: 'Sales Mix Ratio',
        product_sales_mix_ratio_total: 'Sales Mix Ratio (Total)',
        product_number_of_views: 'Number of views',
        product_compared_to_last_month_views: 'Compared to Last Month({{sign}})',
        product_purchase_rate: 'Purchase Rate',
        product_compared_to_last_month_purchase_rate: 'Compared to Last Month(pt)',

        top_sales_products: 'Sales Top {{count}} Products',
        top_trending_products: 'Increase Sales Top {{count}} Products',
        top_worst_products: 'Sales Decline Worst {{count}} Products',
      },
      ec_settings: {
        main_heading: 'E-commerce site linkage',
        sub_heading: 'Please select the e-commerce sites you wish to link to Cross Analytics.',
        ec_site_name: 'EC site name',
        store_name: 'Store name',
        created_at: 'Date created',
        delete_confirmation: 'Confirm deletion',
        delete_confirmation_detail: 'Do you really want to delete the selected aread',
        deletion: 'Deletion',
        shopify: {
          approval: 'Approval',
          approval_heading: 'Allow Cross Analytics to connect with your Shopify store?',
          duplicate_store: 'Shopify store already linked.',
          callback_success: 'Successfully linked Shopify store.',
          callback_error: 'There was an error linking your Shopify store.',
          failed_to_connect_to_shopify: 'There was an error linking your Shopify store.',
          failed_to_queue_old_data: 'Failed to retrieve old data from Shopify',
        },
        rakuten: {
          link_success: 'Successfully linked rakuten store.',
          link_error: 'There was an error linking your rakuten store.',
          invalid_details: 'Invalid rakuten store details.',
          duplicate_store: 'Rakuten store already linked.',
          failed_to_queue_old_data: 'Failed to retrieve old data from Rakuten',
        },
        amazon: {
          approval: 'Approval',
          approval_heading: 'Allow Cross Analytics to connect with your Amazon store?',
          duplicate_store: 'Amazon store already linked.',
          callback_success: 'Successfully linked Amazon store.',
          failed_to_connect_to_amazon: 'There was an error linking your Amazon store.',
          failed_to_queue_old_data: 'Failed to retrieve old data from Amazon',
        },
      },
      rakuten_import: {
        title: 'Rakuten RMS Import',
        missing_ec: 'There are no linked ecommerce site(s).',
        import_success: 'A total of {{rows}} product information imports have been completed.',
        empty_file: 'Cannot import empty products in CSV file.',
        failed_rows: 'Cannot import {{rows}} rows due to following errors',
        failed_row: 'Row {{row}}: {{error}}',
        import_products: 'Click to upload your Rakuten Sales',
        import_product_views: 'Click to upload your Rakuten Traffic',
        invalid_file_name: 'Please use correct filename format and upload once again.',
        column_empty: 'Missing cell value for {{column}} column.',
        column_not_exists: 'Cannot create product because it does not exist yet',
        select_channel: 'Select a channel',
        no_rakuten_linked:
          'To display data in the drop-down menu, please link the Rakuten EC site.',
      },
      not_found: {
        title: 'Page Not Found',
        sub_heading:
          'The page you are looking for may have been deleted or moved to another location.',
        back_to_top: 'Back to Top Page',
      },
      faq: {
        heading: 'FAQ',
        sub_heading: 'We have summarized the questions that customers inquire in a Q&A format.',
      },
      inquiry: {
        heading: 'Inquiry',
        sub_heading: 'If you would like to contact us, please fill out the form below.',
        success_message: 'Your inquiry has been sent.',
        failed_message: 'An error occurred while sending.',
      },
      profile: {
        heading: 'Edit Profile',
        sub_heading: 'Update your account information.',
        success_message: 'Details has been updated successfully.',
        failed_message: 'The update failed.',
      },
      landing: {
        main_heading: 'Welcome to React Base Template!',
        sub_heading: 'A lightweight boilerplate about the development of a React project.',
        why_heading: 'Why use Base Template?',
        docker: {
          heading: 'Flexible Environment',
          description:
            'Eliminate the "it works on my machine" problem once and for all. Spend less time setting up environments, debugging environment-specific issues, and a more portable and easy-to-set-up codebase.',
        },
        react: {
          heading: 'Fast & Intuitive UI',
          description:
            'ReactJS is extremely intuitive to work with and provides interactivity to the layout of any UI. It is composable so we can utilize these components and integrate them into one place. Hence the code becomes a lot more maintainable and flexible.',
        },
        laravel: {
          heading: 'Powerful API',
          description:
            "Utilizing Laravel's API feature for easy backend API development. It's quick, simple with easy 3rd Party integrations and libraries.",
        },
        our_customers_heading: 'Our Clients',
        reviews_heading: 'What our clients say',
        see_all_reviews: 'See All Reviews',
        call_to_action: 'Accelerate your application development today!',
      },
      product_master: {
        usage_heading: 'Usage',
        title: 'List of registered goods',
        import_text: 'Group Registration:',
        export_template_text: 'Registration Template:',
        export_latest_list_text: 'Get latest listing:',
        upload: 'Upload',
        download: 'ダウンロード',
        no_link_site: 'There is no linked ecommerce sites',
        excel_button: {
          menu: 'Excel Upload',
          upload: 'Excel Upload',
          download: 'Excel Upload',
          success_export: 'Success in Exporting the Data',
          success_import: 'Success in Importing the Data',
        },
      },
      product_group: {
        title: '登録グループリスト',
        import_text: 'Group Registration:',
        export_template_text: 'Registration Template:',
        export_latest_list_text: 'Get latest listing:',
        upload: 'Upload',
        download: 'Download',
        group_name: 'Group name:',
        no_of_products: 'Number of products:',
        product_name: 'Brand name:',
        error_download: 'Upload first to acquire latest',
        invalid_upload: 'Unable to import on row {{row}} as no group name or ca_id is selected.',
        export_error: 'Upload first to be able to download product master file.',
      },
      about: {
        main_heading: 'Our Story',
        sub_heading:
          'We work together to design, create and produce works that we are proud of for those we believe in.',
        meet_the_team: 'Meet the team',
        team_description:
          'Thoughtfulness, originality, and attention to detail are the basis for every product we design, build, and market.',
        our_mission: 'Our Mission',
        mission_description:
          'Our mission is to spread the excellence of technology with quality service and products valuing the business trend and proposition with people centric culture and behavior.',
        our_activities: 'Our Activities',
        activities_description: 'Never get so busy making a living that you forget to make a life.',
      },
      user_management: {
        title: 'User List',
        empty_rows: 'No data to display.',
        standard_type: 'Standard',
        admin_type: 'Admin',
        super_admin_type: 'Super Admin',
        delete_user: 'Delete user',
        delete_confirmation: 'Confirmation of deletion',
        delete_confirmation_has_ecsites_linked:
          'This account has an e-commerce site linked to it. If I delete the user account, the linkage to the e-commerce site will also be deleted.',
        delete_confirmation_no_ecsites_linked: 'Delete user account?',
        delete_success_heading: 'Deleteion Completed.',
        delete_success_subheading: 'Account has been deleted successfully.',
        delete_success_has_ecsites_linked:
          'The link between the user account and the e-commerce site has been removed.',
        delete_success_no_ecsites_linked: 'User account has been deleted',
      },
      edit_user: {
        title: 'User List',
        empty: 'No data to display.',
        invalid_user_status: 'Invalid user status.',
        success_heading: 'User details have been updated.',
        success_subheading: 'Account has been updated successfully.',
        form: {
          username: 'ID',
          first_name: 'First Name',
          last_name: 'Last Name',
          email: 'Email',
          type: 'Account Type',
          status: 'Account Status',
        },
      },
      create_user: {
        title: 'Welcome!',
        sub_title: 'Create an account.',
        success_heading: 'Successfully registered.',
        success_subheading:
          'Your account (account ID) has been registered in the system. An email has been sent to you to activate the account.',
        network_error: 'The application has encountered a network error.',
      },
      budget_management: {
        errors: {
          out_of_budget_range: 'The value of this item must be between 0 and 999,999,999 only.',
          dateOutofRange:
            'The Excel file uploaded contains data outside of the selected year range.',
        },
      },
      report_settings: {
        title: 'Report Settings',
        sales_basis: 'Aggregate Sales Basis',
        consumption_tax: 'Consumption Tax',
        orders: 'Orders',
        sales: 'Sales',
        tax_included: 'Tax Included',
        tax_not_included: 'Tax Not Included',
        term_settings: 'Term Settings',
        fiscal_month: 'Start Month of the Fiscal Year',
        fiscal_day: 'Start Day of the Week',
        last_day: 'Last Day of the Month',
        switch_weeks_yes: 'Switch weeks at the end of the month',
        switch_weeks_no: 'Do not Switch weeks at the end of the month',
        dashboard_settings: 'Dashboard Settings',
        number_ranking: 'Number of Rankings',
        product_group: 'Default Display Product Group',
        save: 'Save',
        saving_success: 'Settings have been saved.',
        saving_failed: 'Failed to save settings.',
      },
      terms_of_use: {
        acceptance_of_terms: 'Acceptance of Terms and Conditions',
        change_of_terms: 'Change of Terms and Conditions',
        prohibitions: 'Prohibitions',
      },
      analysis_by_channel: {
        title: 'Analysis By Channel',
        kpi: 'KPI Trends',
        current_month: 'Current Month',
        last_year_month: 'Last Year Month',
        last_year: 'Last Year',
      },
      analysis_by_product: {
        product_name: 'Product Name',
        item_no: 'Item Number',
        ASIN: 'ASIN',
        rakuten_no: 'Rakuten Number',

        monthly_performance: 'Monthly Performance',
        weekly_performance: 'Weekly Performance',
        daily_performance: 'Daily Performance',
      },
    },
    menu: {
      home: 'Home',
      about: 'About',
      inquiry: 'Inquiry',
      faq: 'FAQ',
      orders: 'Orders',
      reports: 'Reports',
      integrations: 'Integrations',
      profile: 'Profile',
      login: 'Login',
      logout: 'Logout',
      terms: 'Terms of Service',
      privacy_policy: 'Privacy Policy',
      documentation: 'Documentation',
      api_reference: 'API Reference',
      styleguide: 'Styleguide',

      // sidebar links - menu
      menu: 'Menu',
      dashboard: 'Dashboard',
      analysis_by_product: 'Analysis by Product',
      analysis_by_channel: 'Analysis by Channel',
      ec: 'Inhouse EC',
      amazon: 'Amazon',
      rakuten: 'Rakuten',
      yahoo: 'Yahoo!',

      // sidebar links - settings
      settings: 'Settings',
      budget_management: 'Budget Management',
      product_master_management: 'Product Master Management',
      product_group_management: 'Product Group Management',
      report_settings: 'Report Settings',
      account_settings: 'Account Settings',
      ec_settings: 'eCommerce Settings',
      rakuten_import: 'Rakuten RMS Import',

      // sidebar links - admin
      admin: 'Admin',
      user_search_list: 'User Search and List Page',
      login_as: 'Login as',
      login_as_activity_log: 'Login as Activity Log',

      // sidebar links - support
      support: 'Support',
      help: 'Help',
      contact_us: 'Contact Us',
      feature_request: 'Feature Request',
      terms_of_use: 'Terms of Use',
    },
    table: {
      no_data: 'No data.',
    },
  },
};

export default en;
