import { createAsyncThunk } from '@reduxjs/toolkit';
import * as userService from 'services/user.service';
import { isAxiosError } from 'utils/api';
import { paginationMetaSelector } from './selectors';
import { getUserResourceError } from './utils';

export const getUsers = createAsyncThunk('user/getUsers', async (params, thunkAPI) => {
  try {
    const { data, meta } = await userService.searchUsers(params);

    return thunkAPI.fulfillWithValue({ list: data, meta });
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.message);
  }
});

export const getUser = createAsyncThunk('user/getUser', async (params, thunkAPI) => {
  try {
    const { data } = await userService.getUser(params);

    return thunkAPI.fulfillWithValue({ data });
  } catch (error) {
    let errorMessage = error?.message;

    if (isAxiosError(error)) {
      errorMessage = getUserResourceError(error);
    }

    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const createUser = createAsyncThunk('user/createUser', async (params, thunkAPI) => {
  try {
    await userService.createUser(params.data);

    // added success to show modal, no need to display success message
    return thunkAPI.fulfillWithValue({ success: 'created successfully' });
  } catch (error) {
    let errorMessage = error?.message;

    if (isAxiosError(error)) {
      errorMessage = getUserResourceError(error);
    }

    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const updateUser = createAsyncThunk('user/updateUser', async (params, thunkAPI) => {
  try {
    const { data } = await userService.updateUser(params.id, params.data);

    // added success to show modal, no need to display success message
    return thunkAPI.fulfillWithValue({ data, success: 'updated successfully' });
  } catch (error) {
    let errorMessage = error?.message;

    if (isAxiosError(error)) {
      errorMessage = getUserResourceError(error);
    }

    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const deleteUser = createAsyncThunk('user/deleteUser', async (params, thunkAPI) => {
  try {
    await userService.deleteUser(params.id);

    const rootState = thunkAPI.getState();
    const meta = paginationMetaSelector(rootState);

    // load users again, use current page as param
    thunkAPI.dispatch(getUsers({ page: meta.currentPage, limit: 10 }));

    // added success to show modal, no need to display success message
    return thunkAPI.fulfillWithValue({ success: 'deleted successfully' });
  } catch (error) {
    let errorMessage = error?.message;

    if (isAxiosError(error)) {
      errorMessage = getUserResourceError(error);
    }

    return thunkAPI.rejectWithValue(errorMessage);
  }
});
