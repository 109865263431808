import { createAsyncThunk } from '@reduxjs/toolkit';
import * as profileService from 'services/profile.service';

export const getAccountProfile = createAsyncThunk(
  'account/getAccountProfile',
  async (_, thunkAPI) => {
    try {
      const result = await profileService.getProfile();
      return thunkAPI.fulfillWithValue(result?.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const updateAccountProfile = createAsyncThunk(
  'account/updateAccountProfile',
  async (payload, thunkAPI) => {
    try {
      const result = await profileService.updateProfile(payload);
      return thunkAPI.fulfillWithValue(result?.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

export const verifyEmail = createAsyncThunk('account/verifyEmail', async (payload, thunkAPI) => {
  try {
    const result = await profileService.verifyEmail(payload);
    return thunkAPI.fulfillWithValue(result?.data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const updatePassword = createAsyncThunk(
  'account/updatePassword',
  async (payload, thunkAPI) => {
    try {
      const result = await profileService.updatePassword(payload);
      return thunkAPI.fulfillWithValue(result?.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  }
);

export const clearSuccessAndLoadingState = createAsyncThunk(
  'account/clearSuccessAndLoadingState',
  async (_, thunkAPI) => {
    return thunkAPI.fulfillWithValue([]);
  }
);
