import { createSlice } from '@reduxjs/toolkit';
import {
  clearSuccessAndLoadingState,
  getAccountProfile,
  updateAccountProfile,
  updatePassword,
  verifyEmail,
} from './actions';

const initialState = {
  isLoading: true,
  error: '',
  account: {},
  success: false,
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get Logged In Account
    builder.addCase(getAccountProfile.pending, (state) => {
      state.isLoading = true;
      state.error = '';
      state.success = false;
    });
    builder.addCase(getAccountProfile.fulfilled, (state, action) => {
      state.isLoading = false;
      state.account = action.payload;
    });
    builder.addCase(getAccountProfile.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // Update Profile
    builder.addCase(updateAccountProfile.pending, (state) => {
      state.isLoading = true;
      state.error = '';
      state.success = false;
    });
    builder.addCase(updateAccountProfile.fulfilled, (state) => {
      state.isLoading = false;
      state.success = true;
    });
    builder.addCase(updateAccountProfile.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
    });

    builder.addCase(verifyEmail.pending, (state) => {
      state.isLoading = true;
      state.error = '';
      state.success = false;
    });
    builder.addCase(verifyEmail.fulfilled, (state) => {
      state.isLoading = false;
      state.success = true;
    });
    builder.addCase(verifyEmail.rejected, (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    });

    builder.addCase(updatePassword.pending, (state) => {
      state.isLoading = true;
      state.error = '';
      state.success = false;
    });
    builder.addCase(updatePassword.fulfilled, (state) => {
      state.isLoading = false;
      state.success = true;
    });
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    });

    //clear state
    builder.addCase(clearSuccessAndLoadingState.fulfilled, (state) => {
      state.isLoading = false;
      state.success = false;
      state.error = '';
    });
  },
});

export default accountSlice.reducer;

export const actions = {
  ...accountSlice.actions,
  getAccountProfile,
  updateAccountProfile,
  verifyEmail,
  clearSuccessAndLoadingState,
  updatePassword,
};

export * as selectors from './selectors';
