import { createSelector } from '@reduxjs/toolkit';

const rootSelector = createSelector(
  (state) => state.account,
  (account) => account
);

export const isLoadingSelector = createSelector(rootSelector, (account) => account.isLoading);

export const userAccountSelector = createSelector(rootSelector, (account) => account.account);

export const userAccountError = createSelector(rootSelector, (account) => account.error);

export const isSuccessSelector = createSelector(rootSelector, (account) => account.success);
