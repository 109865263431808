import api from 'utils/api';

const getAnalysisByChannel = async (params) => {
  const query = {
    ...(params?.channelStoreId && { channelStoreId: params.channelStoreId }),
    ...(params?.from && { from: params.from }),
    ...(params?.to && { to: params.to }),
    ...(params?.productGroupId && { productGroupId: params.productGroupId }),
  };
  const { data } = await api.get(`/analytics/channels?${new URLSearchParams(query)}`);

  return data;
};

const getAnalysisByProduct = async (params) => {
  const query = {
    ...(params?.period && { period: params.period }),
    ...(params?.from && { from: params.from }),
    ...(params?.to && { to: params.to }),
    ...(params?.caId && { caId: params.caId }),
  };
  const { data } = await api.get(`/analytics/products?${new URLSearchParams(query)}`);

  return data;
};

const getPerformances = async (params) => {
  const { period, ...rest } = params;
  const query = {
    period,
    ...(rest?.from && { from: rest.from }),
    ...(rest?.to && { to: rest.to }),
    ...(rest?.productGroupId && { productGroupId: rest.productGroupId }),
  };
  const { data } = await api.get(`/analytics/performances?${new URLSearchParams(query)}`);

  return data;
};

const getGraphPerformances = async (params) => {
  const { period, ...rest } = params;
  const query = {
    period,
    ...(rest?.channelId && { channelId: rest.channelId }),
    ...(rest?.from && { from: rest.from }),
    ...(rest?.to && { to: rest.to }),
    ...(rest?.productGroupId && { productGroupId: rest.productGroupId }),
  };
  const { data } = await api.get(`/analytics/performances/graph?${new URLSearchParams(query)}`);

  return data;
};

const getProductRanks = async (params) => {
  const { type, ...rest } = params;
  const query = {
    type,
    ...(rest?.period && { period: rest.period }),
    ...(rest?.channelStoreId && { channelStoreId: rest.channelStoreId }),
    ...(rest?.productGroupId && { productGroupId: rest.productGroupId }),
    ...(rest?.purpose && { purpose: rest.purpose }),
    ...(rest?.date && { date: rest.date }),
    ...(rest?.from && { from: rest.from }),
    ...(rest?.to && { to: rest.to }),
  };
  const { data } = await api.get(`/analytics/product-rankings?${new URLSearchParams(query)}`);

  return data;
};

const getAvailableDates = async (params) => {
  const query = {
    ...(params?.channelStoreId && { channelStoreId: params.channelStoreId }),
    ...(params?.period && { period: params.period }),
    ...(params?.productId && { productId: params.productId }),
    ...(params?.productGroupId && { productGroupId: params.productGroupId }),
  };
  const { data } = await api.get(`/analytics/performances/dates?${new URLSearchParams(query)}`);

  return data;
};

export {
  getAnalysisByChannel,
  getAnalysisByProduct,
  getAvailableDates,
  getPerformances,
  getProductRanks,
  getGraphPerformances,
};
