import { createSlice } from '@reduxjs/toolkit';
import {
  getAnalysisByChannel,
  getAvailableDates,
  getStoreProductGroups,
  getTopSalesProducts,
  getTopTrendingProducts,
  getTopWorstProducts,
} from './actions';

const initialState = {
  analysis: {
    error: '',
    isLoading: false,
    data: [],
  },
  productGroups: {
    error: '',
    isLoading: false,
    data: [],
  },
  topSalesProducts: {
    error: '',
    isLoading: false,
    data: undefined,
  },
  topTrendingProducts: {
    error: '',
    isLoading: false,
    data: undefined,
  },
  topWorstProducts: {
    error: '',
    isLoading: false,
    data: undefined,
  },
  availableDates: {
    monthly: [],
    isLoading: false,
  },
};

const analysisByChannelSlice = createSlice({
  name: 'analysis_by_channel',
  initialState,
  reducers: {
    setProductGroups: (state, action) => {
      state.productGroups.data = action.payload;
    },
    setAnalysis: (state, action) => {
      state.analysis.data = action.payload;
    },
    setTopSalesProducts: (state, action) => {
      state.topSalesProducts.data = action.payload;
    },
    setTreandingProducts: (state, action) => {
      state.topTrendingProducts.data = action.payload;
    },
    setWorstProducts: (state, action) => {
      state.topWorstProducts.data = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Get Available Dates
    builder.addCase(getAvailableDates.pending, (state) => {
      state.availableDates.isLoading = true;
      state.availableDates.monthly = [];
    });
    builder.addCase(getAvailableDates.fulfilled, (state, action) => {
      state.availableDates.isLoading = false;
      state.availableDates.monthly = action.payload?.data?.monthly;
    });
    builder.addCase(getAvailableDates.rejected, (state, action) => {
      state.availableDates.isLoading = false;
      state.availableDates.error = action.payload;
    });

    // Get Analysis by channel
    builder.addCase(getAnalysisByChannel.pending, (state) => {
      state.analysis.isLoading = true;
      state.analysis.error = '';
    });
    builder.addCase(getAnalysisByChannel.fulfilled, (state, action) => {
      state.analysis.isLoading = false;
      state.analysis.data = action.payload.data;
    });
    builder.addCase(getAnalysisByChannel.rejected, (state, action) => {
      state.analysis.isLoading = false;
      state.analysis.error = action.payload;
    });

    // Get Store Products
    builder.addCase(getStoreProductGroups.pending, (state) => {
      state.productGroups.isLoading = true;
      state.productGroups.data = [];
      state.productGroups.error = '';
    });
    builder.addCase(getStoreProductGroups.fulfilled, (state, action) => {
      state.productGroups.isLoading = false;
      state.productGroups.data = action.payload.data;
    });
    builder.addCase(getStoreProductGroups.rejected, (state, action) => {
      state.productGroups.isLoading = false;
      state.productGroups.error = action.payload;
    });

    // Get Top Sales Products
    builder.addCase(getTopSalesProducts.pending, (state) => {
      state.topSalesProducts.isLoading = true;
      state.topSalesProducts.error = '';
    });
    builder.addCase(getTopSalesProducts.fulfilled, (state, action) => {
      state.topSalesProducts.isLoading = false;
      state.topSalesProducts.data = action.payload.data;
    });
    builder.addCase(getTopSalesProducts.rejected, (state, action) => {
      state.topSalesProducts.isLoading = false;
      state.topSalesProducts.error = action.payload;
    });

    // Get Top Trending Products
    builder.addCase(getTopTrendingProducts.pending, (state) => {
      state.topTrendingProducts.isLoading = true;
      state.topTrendingProducts.error = '';
    });
    builder.addCase(getTopTrendingProducts.fulfilled, (state, action) => {
      state.topTrendingProducts.isLoading = false;
      state.topTrendingProducts.data = action.payload.data;
    });
    builder.addCase(getTopTrendingProducts.rejected, (state, action) => {
      state.topTrendingProducts.isLoading = false;
      state.topTrendingProducts.error = action.payload;
    });

    // Get Top Wordst products
    builder.addCase(getTopWorstProducts.pending, (state) => {
      state.topWorstProducts.isLoading = true;
      state.topWorstProducts.error = '';
    });
    builder.addCase(getTopWorstProducts.fulfilled, (state, action) => {
      state.topWorstProducts.isLoading = false;
      state.topWorstProducts.data = action.payload.data;
    });
    builder.addCase(getTopWorstProducts.rejected, (state, action) => {
      state.topWorstProducts.isLoading = false;
      state.topWorstProducts.error = action.payload;
    });
  },
});

export const actions = {
  ...analysisByChannelSlice.actions,
  getAvailableDates,
  getAnalysisByChannel,
  getStoreProductGroups,
  getTopSalesProducts,
  getTopTrendingProducts,
  getTopWorstProducts,
};

export * as selectors from './selectors';

export default analysisByChannelSlice.reducer;
