import { createSelector } from '@reduxjs/toolkit';

const rootSelector = createSelector(
  (state) => state.app,
  (app) => app
);

export const isInitializedSelector = createSelector(rootSelector, (app) => app.isInitialized);

export const isLoadingSelector = createSelector(rootSelector, (app) => app.isLoading);

export const isNoLinkedStoreModalOpenSelector = createSelector(
  rootSelector,
  (app) => app.isNoLinkedStoresModalOpen
);
