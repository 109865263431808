import api from 'utils/api';

const searchProducts = async (params) => {
  const { keyword } = params;
  const query = { keyword };
  const { data } = await api.get(`/products?${new URLSearchParams(query)}`);

  return data;
};

const getProduct = async (params) => {
  const { caId } = params;
  const { data } = await api.get(`/products/${caId}`);

  return data;
};

export { getProduct, searchProducts };
