import { createSlice } from '@reduxjs/toolkit';
import { initialize } from './actions';

const initialState = {
  isLoading: false,
  isInitialized: false,
  isNoLinkedStoresModalOpen: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsNoLinkedStoresModalOpen: (state, { payload }) => ({
      ...state,
      isNoLinkedStoresModalOpen: payload,
    }),
  },
  extraReducers: (builder) => {
    // initialize
    builder.addCase(initialize.pending, (state) => ({
      ...state,
      isLoading: true,
    }));
    builder.addCase(initialize.fulfilled, (state) => ({
      ...state,
      isInitialized: true,
      isLoading: false,
    }));
    builder.addCase(initialize.rejected, (state) => ({
      ...state,
      isLoading: false,
    }));
  },
});

export const actions = {
  ...appSlice.actions,
  initialize,
};

export * as selectors from './selectors';

export default appSlice.reducer;
