import { createAsyncThunk } from '@reduxjs/toolkit';
import { actions as authActions } from 'modules/auth';
import { loadStandardUserResources } from 'modules/auth/utils';
import { actions as profileActions } from 'modules/profile';
import * as profileService from 'services/profile.service';
import { setAccessToken } from 'utils/api';

export const initialize = createAsyncThunk('app/initialize', async (_, thunkAPI) => {
  const accessToken = localStorage.getItem('access_token');

  if (accessToken) {
    setAccessToken(accessToken);

    try {
      const profile = await profileService.getProfile();

      await thunkAPI.dispatch(profileActions.setProfileData(profile.data));

      // retrieve standard data
      if (profile.data?.role?.toLowerCase() === 'standard') {
        await loadStandardUserResources(thunkAPI);
      }
    } catch (error) {
      setAccessToken('');

      localStorage.clear();

      return thunkAPI.rejectWithValue(error?.message);
    }

    await thunkAPI.dispatch(authActions.setIsAuth(true));
  }

  return thunkAPI.fulfillWithValue();
});
