import api from 'utils/api';

const getStores = async () => {
  const { data } = await api.get('/stores');

  return data;
};

const deleteStore = async (id) => await api.delete('/stores/' + id);

const updateStore = async (store) => {
  const { data } = await api.post('/stores/' + store?.id, { store_name: store?.storeName });
  return data;
};

const getProductGroups = async (id) => {
  const { data } = await api.get(`/stores/${id}/product-groups`);

  return data;
};

export { getProductGroups, getStores, deleteStore, updateStore };
