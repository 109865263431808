import { createAsyncThunk } from '@reduxjs/toolkit';
import * as analyticsService from 'services/analytics.service';
import * as productService from 'services/products.service';

export const getAvailableDates = createAsyncThunk(
  'analysis_by_product/getAvailableDates',
  async (payload, thunkAPI) => {
    try {
      const result = await analyticsService.getAvailableDates(payload);

      return thunkAPI.fulfillWithValue({ data: result?.data });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const searchProducts = createAsyncThunk(
  'analysis_by_product/searchProducts',
  async (payload, thunkAPI) => {
    try {
      const result = await productService.searchProducts(payload);

      return thunkAPI.fulfillWithValue({ data: result?.data });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getDailyPerformance = createAsyncThunk(
  'analysis_by_product/getDailyPerformance',
  async (payload, thunkAPI) => {
    try {
      const result = await analyticsService.getAnalysisByProduct({
        ...payload,
        period: 'daily',
      });

      return thunkAPI.fulfillWithValue({ data: result?.data?.per_channel });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getWeeklyPerformance = createAsyncThunk(
  'analysis_by_product/getWeeklyPerformance',
  async (payload, thunkAPI) => {
    try {
      const result = await analyticsService.getAnalysisByProduct({
        ...payload,
        period: 'weekly',
      });

      return thunkAPI.fulfillWithValue({ data: result?.data?.per_channel });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getMonthlyPerformance = createAsyncThunk(
  'analysis_by_product/getMonthlyPerformance',
  async (payload, thunkAPI) => {
    try {
      const result = await analyticsService.getAnalysisByProduct({
        ...payload,
        period: 'monthly',
      });

      return thunkAPI.fulfillWithValue({ data: result?.data?.per_channel });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getProduct = createAsyncThunk(
  'analysis_by_product/getProduct',
  async (payload, thunkAPI) => {
    try {
      const result = await productService.getProduct(payload);

      return thunkAPI.fulfillWithValue({ data: result?.data });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);
