import { Provider as ReduxProvider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from '@mui/material/styles';
import store from './modules/store';
import Router from './router';
import theme from './theme';

function App() {
  return (
    <ReduxProvider store={store}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ width: 'auto' }}
      />
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </ReduxProvider>
  );
}

export default App;
