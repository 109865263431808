import i18next from 'i18next';

export const getUserResourceError = (error) => {
  let message = '';

  if (error?.response?.status === 404) {
    message = i18next.t('pages.edit_user.empty');
  }

  if (error?.response?.status === 422 && error?.response?.data?.error?.username) {
    message = i18next.t('form.username.already_exist');
  }

  if (error?.response?.status === 422 && error?.response?.data?.error?.email) {
    message = i18next.t('form.email.already_exist');
  }

  if (error?.response?.data?.error) {
    const errorType = error.response.data.error;
    switch (errorType) {
      case 'user_not_found':
        message = i18next.t('pages.edit_user.empty');
        break;
      case 'user_status_not_found':
        message = i18next.t('pages.ec_settings.rakuten.invalid_details');
        break;
    }
  }

  if (error?.message === 'Network Error') {
    message = i18next.t('form.network_error');
  }

  return message;
};
