import { createAsyncThunk } from '@reduxjs/toolkit';
import * as reportSettingsService from 'services/reportSettings.service';
import FiscalCalendar from 'utils/fiscalCalendar';
import * as selectors from './selectors';

export const updateReportSettings = createAsyncThunk(
  'reportSettings/updateReportSettings',
  async (params, thunkAPI) => {
    try {
      const state = thunkAPI.getState();
      const reportSettings = selectors.dataSelector(state);
      const { data } = await reportSettingsService.updateReportSettings(params);
      const shouldRefreshFiscalCalendar =
        reportSettings.fiscal_month !== data.fiscal_month ||
        reportSettings.fiscal_day !== data.fiscal_day;

      if (shouldRefreshFiscalCalendar) {
        const fiscalCalendar = new FiscalCalendar(
          new Date().getFullYear(),
          data.fiscal_month,
          data.fiscal_day
        );

        return thunkAPI.fulfillWithValue({ data, fiscalCalendar, success: 'updated successfully' });
      }

      return thunkAPI.fulfillWithValue({ data, success: 'updated successfully' });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);
