import { createSelector } from '@reduxjs/toolkit';

const rootSelector = createSelector(
  (state) => state.user,
  (user) => user
);

export const isLoadingSelector = createSelector(rootSelector, (user) => user.isLoading);

export const errorSelector = createSelector(rootSelector, (user) => user.error);

export const successSelector = createSelector(rootSelector, (user) => user.success);

export const dataSelector = createSelector(rootSelector, (user) => user.data);

export const listSelector = createSelector(rootSelector, (user) => user.list);

export const paginationMetaSelector = createSelector(rootSelector, (user) => user.meta);
