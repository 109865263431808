import { createSlice } from '@reduxjs/toolkit';
import { deleteStore, getStores, linkChannel, updateStore } from './actions';

const initialState = {
  isAuth: false,
  isLoading: false,
  isLinkingLoading: false,
  isUploadProductsLoading: false,
  error: '',
  stores: [],
  success: '',
};

const ecChannelSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setStores: (state, action) => {
      state.stores = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get stores
    builder.addCase(getStores.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getStores.fulfilled, (state, action) => {
      state.isLoading = false;
      state.stores = action.payload;
    });
    builder.addCase(getStores.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // link channel
    builder.addCase(linkChannel.pending, (state) => {
      state.isLinkingLoading = true;
      state.error = '';
    });
    builder.addCase(linkChannel.fulfilled, (state) => {
      state.isLinkingLoading = false;
    });
    builder.addCase(linkChannel.rejected, (state, action) => {
      state.isLinkingLoading = false;
      state.error = action.payload;
    });

    // edit store
    builder.addCase(updateStore.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(updateStore.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    });
    builder.addCase(updateStore.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // delete store
    builder.addCase(deleteStore.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(deleteStore.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload;
    });
    builder.addCase(deleteStore.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default ecChannelSlice.reducer;

export const actions = {
  ...ecChannelSlice.actions,
  getStores,
  linkChannel,
  updateStore,
  deleteStore,
};

export * as selectors from './selectors';
