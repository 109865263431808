import { createSlice } from '@reduxjs/toolkit';
import { importProducts, importRakutenProductViews } from './actions';

const initialState = {
  uploadProgress: 0,
  isLoading: false,
  error: '',
  success: '',
  failedRows: [],
};

const rakutenImportSlice = createSlice({
  name: 'rakutenImport',
  initialState,
  reducers: {
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setUploadProgress: (state, action) => {
      state.uploadProgress = action.payload;
    },
  },
  extraReducers: (builder) => {
    // import products
    builder.addCase(importProducts.pending, (state) => {
      state.uploadProgress = 0;
      state.isLoading = true;
      state.failedRows = [];
      state.error = '';
      state.success = '';
    });
    builder.addCase(importProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.message;

      if (action.payload?.failedRows?.length) {
        state.failedRows = action.payload.failedRows;
      }
    });
    builder.addCase(importProducts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // import porduct views
    builder.addCase(importRakutenProductViews.pending, (state) => {
      state.uploadProgress = 0;
      state.isLoading = true;
      state.failedRows = [];
      state.error = '';
      state.success = '';
    });
    builder.addCase(importRakutenProductViews.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = action.payload.message;

      if (action.payload?.failedRows?.length) {
        state.failedRows = action.payload.failedRows;
      }
    });
    builder.addCase(importRakutenProductViews.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const actions = {
  ...rakutenImportSlice.actions,
  importProducts,
  importRakutenProductViews,
};

export * as selectors from './selectors';

export default rakutenImportSlice.reducer;
