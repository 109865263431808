import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { createUser, deleteUser, getUser, getUsers, updateUser } from './actions';

const initialState = {
  data: undefined,
  list: [],
  meta: undefined,
  //   meta: {
  //     total: 0,
  //     currentPage: 0,
  //     lastPage: 0,
  //     perPage: 0,
  //     firstPageUrl: '',
  //     previousPageUrl: null,
  //     nextPageUrl: '',
  //     url: '',
  //   },
  isLoading: false,
  isListLoading: false,
  error: '',
  success: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.isListLoading = true;
      state.error = '';
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.isListLoading = false;
      state.list = action.payload.list;
      state.meta = action.payload.meta;
    });
    builder.addCase(getUsers.rejected, (state, action) => {
      state.isListLoading = false;
      state.error = action.payload;
    });

    // matchers
    builder.addMatcher(
      isAnyOf(createUser.pending, deleteUser.pending, getUser.pending, updateUser.pending),
      (state) => {
        state.isLoading = true;
        state.error = '';
      }
    );
    builder.addMatcher(
      isAnyOf(createUser.fulfilled, deleteUser.fulfilled, getUser.fulfilled, updateUser.fulfilled),
      (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data || undefined;
        state.success = action.payload?.success || '';
      }
    );
    builder.addMatcher(
      isAnyOf(createUser.rejected, deleteUser.rejected, getUser.rejected, updateUser.rejected),
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      }
    );
  },
});

export const actions = {
  ...userSlice.actions,
  createUser,
  deleteUser,
  getUsers,
  getUser,
  updateUser,
};

export * as selectors from './selectors';

export default userSlice.reducer;
