const routes = [
  // Dont Remove. Handle 404 Pages
  {
    path: '*',
    component: 'pages/guest/NotFound',
  },
  {
    path: '/dashboard',
    component: 'containers/DashboardContainer',
    auth: true,
  },
  {
    path: '/ec-settings',
    component: 'containers/ECSettingsContainer',
    auth: true,
    roles: ['standard'],
  },
  {
    path: '/rakuten/import',
    component: 'containers/RakutenImportContainer',
    auth: true,
  },
  {
    path: '/login',
    component: 'containers/LoginContainer',
    auth: false,
  },
  {
    path: '/forgot-password',
    component: 'containers/ForgotPasswordContainer',
    auth: false,
  },
  {
    path: '/password/reset',
    component: 'containers/PasswordResetContainer',
    layout: 'guest',
  },
  {
    path: '/password/register',
    component: 'containers/RegisterPasswordContainer',
    layout: 'guest',
    auth: false,
  },
  {
    path: '/users',
    component: 'containers/UserManagementContainer',
    auth: true,
    roles: ['admin', 'superadmin'],
  },
  {
    path: '/users/create',
    component: 'containers/CreateUserContainer',
    auth: true,
    roles: ['admin', 'superadmin'],
  },
  {
    path: '/users/edit/:id',
    component: 'containers/EditUserContainer',
    auth: true,
    roles: ['admin', 'superadmin'],
  },
  {
    path: '/report-settings',
    component: 'containers/ReportSettingsContainer',
    auth: true,
    roles: ['standard'],
  },
  {
    path: '/product-master-management',
    component: 'containers/ProductMasterManagementContainer',
    auth: true,
    roles: ['standard'],
  },
  {
    path: '/product-group-management',
    component: 'containers/ProductGroupManagementContainer',
    auth: true,
    roles: ['standard'],
  },
  {
    path: '/budget-management',
    component: 'containers/BudgetManagementContainer',
    auth: true,
    roles: ['standard'],
  },
  {
    path: '/analysis-by-channel/:id',
    component: 'containers/AnalysisByChannelContainer',
    auth: true,
    roles: ['standard'],
  },
  {
    path: '/analysis-by-product',
    component: 'containers/AnalysisByProductContainer',
    auth: true,
    roles: ['standard'],
  },
  {
    path: '/account-settings',
    component: 'containers/AccountSettingsContainer',
    auth: true,
  },
  {
    path: '/email/change',
    component: 'containers/EmailChangeContainer',
    layout: 'guest',
  },
  {
    path: '/terms-of-use',
    component: 'containers/TermsOfUseGuestContainer',
    layout: 'guest',
  },
  {
    path: '/terms-of-use-auth',
    component: 'containers/TermsOfUseAuthContainer',
    auth: true,
    roles: ['standard', 'admin'],
  },
  {
    path: '/help',
    component: 'containers/HelpGuestContainer',
    auth: false,
    roles: ['guest'],
  },
  {
    path: '/help-auth',
    component: 'containers/HelpAuthContainer',
    auth: true,
    roles: ['standard'],
  },
];

// Don't include styleguide in production routes
if (process.env.ENVIRONMENT !== 'production') {
  routes.push({
    path: '/styleguide',
    component: 'pages/guest/Styleguide',
    auth: false,
  });
}

export default routes;
