import api from 'utils/api';

const exportData = async () => await api.get('/product/master/exported-data-list');

const fetchProductMaster = async (query) => {
  const { data } = await api.get(`/product/master?${new URLSearchParams(query).toString()}`);

  return data;
};

const isLinkChannel = async () => await api.get('/product/master/check-link');

const deleteProductMasterList = async (id) => await api.delete('/product/master/' + id);

const importData = async (file) => {
  await api.post(
    '/product/master/import',
    { file: file },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

export { exportData, fetchProductMaster, isLinkChannel, deleteProductMasterList, importData };
