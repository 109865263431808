import api from 'utils/api';

const searchUsers = async (query) => {
  const { data } = await api.get(`/users?${new URLSearchParams(query).toString()}`);

  return data;
};

const createUser = async (data) => api.post('/users', data);

const getUser = async (id) => api.get(`/users/${id}`);

const updateUser = async (id, data) => api.patch(`/users/${id}`, data);

const deleteUser = async (id) => api.delete(`/users/${id}`);

export { searchUsers, createUser, getUser, updateUser, deleteUser };
