/**
 * MUI Palette Override
 * @see https://mui.com/material-ui/customization/palette/
 */
const palette = {
  primary: {
    main: '#026195',
    contrastText: '#fff',
  },
  secondary: {
    main: '#D14081',
    contrastText: '#fff',
  },
  success: {
    main: '#70A37F',
    contrastText: '#fff',
  },
  error: {
    main: '#FE6D73',
    contrastText: '#fff',
  },
  warning: {
    main: '#F3C969',
    contrastText: '#fff',
  },
  info: {
    main: '#9DD1F1',
    contrastText: '#fff',
  },
  transparent: {
    main: '#fff',
    dark: '#888',
  },
  divider: 'rgba(0,0,0,0.05)',
  blueLink: {
    main: '#0B69FF',
    contrastText: '#fff',
  },
  blackButton: {
    main: '#141D3D',
    contrastText: '#fff',
  },
  grayButton: {
    main: '#E7E7E7',
    contrastText: '#000',
  },
};

export default palette;
