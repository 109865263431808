import { actions, selectors } from 'modules/app';
import { Suspense, lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import Loader from 'components/atoms/Loader';
import NotFound from 'pages/guest/NotFound';
import routes from './routes';

function Router() {
  const dispatch = useDispatch();
  const isInitialized = useSelector(selectors.isInitializedSelector);

  const Auth = lazy(() => import('templates/Authenticated'));
  const GuestTemplate = lazy(() => import('templates/Guest'));
  const NoLinkedStoreModal = lazy(() => import('containers/NoLinkedStoreModalContainer'));

  useEffect(() => {
    dispatch(actions.initialize());
  }, [dispatch]);

  if (!isInitialized) {
    return (
      <Backdrop
        sx={{
          color: (theme) => theme.palette.primary.main,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Suspense fallback={<Loader />}>
      {/* extras */}
      <NoLinkedStoreModal />

      <Routes>
        <Route path="*" element={<NotFound />} />

        {/* redirect landing page to login page */}
        <Route path="/" element={<Navigate to="/login" />} />

        {routes.map((route, i) => {
          const Page =
            typeof route.component === 'string'
              ? lazy(() => import(`../${route.component}`))
              : route.component;
          const roles = route.roles || [];
          const layout = route.auth ? (
            <Auth roles={roles} />
          ) : (
            <GuestTemplate navbar={route.navbar} />
          );

          return (
            <Route key={i} element={layout}>
              <Route exact path={route.path} element={<Page />} />
            </Route>
          );
        })}
      </Routes>
    </Suspense>
  );
}

export default Router;
