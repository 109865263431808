import { createSlice } from '@reduxjs/toolkit';
import { clearBugdet, getBudget, saveBudget } from './actions';

const initialState = {
  isLoading: true,
  error: '',
  budgetUserChannels: [],
  userBudget: [],
};

const budgetSlice = createSlice({
  name: 'budget',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Save Budget
    builder.addCase(saveBudget.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(saveBudget.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(saveBudget.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    // Get Budget
    builder.addCase(getBudget.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(getBudget.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userBudget = action.payload;
    });
    builder.addCase(getBudget.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });

    //Clear Budget
    builder.addCase(clearBugdet.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(clearBugdet.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userBudget = action.payload;
    });
  },
});

export default budgetSlice.reducer;

export const actions = {
  ...budgetSlice.actions,
  saveBudget,
  getBudget,
  clearBugdet,
};

export * as selectors from './selectors';
