import i18next from 'i18next';

const str2Hex = (str) => {
  var arr1 = [];
  for (var n = 0, l = str.length; n < l; n++) {
    var hex = Number(str.charCodeAt(n)).toString(16);
    arr1.push(hex);
  }
  return arr1.join('');
};

export const generateShopifyAuthUrl = (shopUrl, profileId) => {
  if (shopUrl === '') {
    return `${process.env.REACT_APP_SHOPIFY_APP_LISTING_PAGE}`;
  }

  const clientId = process.env.REACT_APP_SHOPIFY_CLIENT_ID;
  const apiUrl = process.env.REACT_APP_API_URL;
  const redirectUri = encodeURIComponent(`${apiUrl}/shopify/auth/callback`); // handleCallback()
  const scopes = encodeURIComponent(
    process.env.REACT_APP_SHOPIFY_SCOPE ||
      'read_orders,read_reports,read_customers,read_fulfillments,read_inventory,read_products,read_product_listings,read_all_orders'
  );

  const state = `${shopUrl}*${profileId}`;
  const convertedState = str2Hex(state);
  return `https://${shopUrl}/admin/oauth/authorize?client_id=${clientId}&scope=${scopes}&redirect_uri=${redirectUri}&state=${convertedState}&grant_options[]=offline`;
};

export const generateAmazonAuthUrl = (amazonUrl, profileId) => {
  const appId = process.env.REACT_APP_AMAZON_APP_ID;

  return `${amazonUrl}/apps/authorize/consent?application_id=${appId}&version=beta&state=${profileId}`;
};

export const getRakutenLinkingError = (error) => {
  let message = '';

  if (error?.response?.data?.error) {
    const errorType = error.response.data.error;
    switch (errorType) {
      case 'general_error':
        message = i18next.t('pages.ec_settings.rakuten.link_error');
        break;
      case 'unauthorized':
        message = i18next.t('pages.ec_settings.rakuten.invalid_details');
        break;
      case 'duplicate_access_token':
        message = i18next.t('pages.ec_settings.rakuten.duplicate_store');
        break;
      case 'failed_to_queue_old_data_from_rakuten':
        message = i18next.t('pages.ec_settings.rakuten.failed_to_queue_old_data');
        break;
    }
  }

  return message;
};

export const getRakutenImportProductError = (error) => {
  let message = '';

  if (error?.response?.data?.error) {
    const errorType = error.response.data.error;
    switch (errorType) {
      case 'channel_not_found':
        message = i18next.t('pages.rakuten_import.missing_ec');
        break;
      case 'empty_file':
        message = i18next.t('pages.rakuten_import.empty_file');
        break;
    }
  }

  return message;
};
