import { createAsyncThunk } from '@reduxjs/toolkit';
import * as budgetService from 'services/budget.service';

export const saveBudget = createAsyncThunk('budget/saveBudget', async (payload, thunkAPI) => {
  try {
    if (payload.allocations.length > 0) {
      const result = await budgetService.saveBudget(payload);
      return thunkAPI.fulfillWithValue(result?.data);
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.response.data.error);
  }
});

export const getBudget = createAsyncThunk('budget/getBudget', async (payload, thunkAPI) => {
  try {
    const result = await budgetService.getBudget(payload);
    return thunkAPI.fulfillWithValue(result?.data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.message);
  }
});

export const clearBugdet = createAsyncThunk('budget/clearBugdet', async (_, thunkAPI) => {
  return thunkAPI.fulfillWithValue([]);
});
