import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';
import * as productGroupService from 'services/product.group.service';

export const fetchProductGroup = createAsyncThunk(
  'product_group/fetch_product_group',
  async (params, thunkAPI) => {
    try {
      const { data, meta } = await productGroupService.fetchProductGroup(params);
      return thunkAPI.fulfillWithValue({ list: data, meta });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const fetchProductMaster = createAsyncThunk(
  'product_group/fetch_product_master',
  async (payload, thunkAPI) => {
    try {
      const result = await productGroupService.fetchProductMaster(payload);
      return thunkAPI.fulfillWithValue(result?.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const fetchExportData = createAsyncThunk('product_group/export', async (_, thunkAPI) => {
  try {
    const { data } = await productGroupService.exportData();
    return thunkAPI.fulfillWithValue(data);
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.message);
  }
});

export const importProductGroup = createAsyncThunk(
  'product_group/import',
  async (payload, thunkAPI) => {
    try {
      await productGroupService.importProductGroup(payload);
      const result = i18next.t('pages.product_master.excel_button.success_import');
      return thunkAPI.fulfillWithValue(result?.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.data?.error);
    }
  }
);

export const deleteProductGroup = createAsyncThunk(
  'product_group/delete',
  async (payload, thunkAPI) => {
    try {
      const result = await productGroupService.deleteProductGroup(payload);
      return thunkAPI.fulfillWithValue(result?.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);
