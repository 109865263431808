import dayjs from 'dayjs';
import ISOWeek from 'dayjs/plugin/isoWeek';
import { isLeapYear, isSameOrBefore } from './date';

dayjs.extend(ISOWeek);

// dayjs unit ranges
// months = 0 - 11, 0 - january, 11 - december
// startOfWeek = 0 - 6, 0 - sunday, 6 - saturday
class FiscalCalendar {
  constructor(year, startingMonth, startOfWeek) {
    this.fiscalYear = year;

    // since fiscal month from BE is 1 - 12, we need to subtract 1 to get the correct fiscal month
    this.fiscalMonth = startingMonth - 1;

    // since fiscal day from BE is 1 - 7 (1 - sunday, 7 - saturday), we need to subtract 1 to get the correct fiscal Day
    this.fiscalDay = startOfWeek > 0 ? startOfWeek - 1 : startOfWeek;
    this.totalWeeks = isLeapYear(new Date(year, 0)) ? 53 : 52;

    // if startOfWeek is 0 (sunday), use 6 (saturday) as endOfWeek
    // otherwise simply subtract 1 from startOfWeek
    const endOfWeek = this.fiscalDay > 0 ? this.fiscalDay - 1 : 6;

    this.start = dayjs()
      .year(this.fiscalYear)
      .month(this.fiscalMonth)
      .startOf('month')
      .startOf('week')
      .add(this.fiscalDay, 'day')
      .format('YYYY-MM-DD');

    this.end = dayjs(this.start)
      .clone()
      .add(this.totalWeeks, 'week')
      .endOf('week')
      .subtract((6 - endOfWeek) % 7, 'day')
      .format('YYYY-MM-DD');

    this.weeks = this.generateWeeks(dayjs(this.start));

    this.months = this.generateMonths(
      dayjs()
        .year(year)
        .month(startingMonth - 1)
        .startOf('month')
    );
  }

  static getFiscalYearFromSettings(fiscalMonth) {
    const now = dayjs();

    if (now.month() < fiscalMonth) {
      return now.year() - 1;
    }

    return now.year();
  }

  generateWeeks(startDate) {
    const weeks = [];
    let weeksCount = 1;
    let start = startDate.clone();

    while (weeksCount <= this.totalWeeks) {
      const startDateOfWeek = start.clone();
      const endDateOfWeek = startDateOfWeek.clone().add(6, 'day');

      const dates = [];

      for (
        let date = startDateOfWeek;
        isSameOrBefore(date, endDateOfWeek);
        date = date.add(1, 'day')
      ) {
        dates.push(date.format('YYYY-MM-DD'));
      }

      weeks.push({
        dates,
        id: weeksCount,
      });

      start = start.add(1, 'week');
      weeksCount++;
    }

    return weeks;
  }

  generateMonths(startDate) {
    const months = [];
    let monthsCount = 1;
    let start = startDate.clone();

    while (monthsCount <= 12) {
      months.push(start.startOf('month').format('YYYY-MM-DD'));
      start = start.add(1, 'month');
      monthsCount++;
    }

    return months;
  }
}

export default FiscalCalendar;
