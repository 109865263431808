import { createSelector } from '@reduxjs/toolkit';

const rootSelector = createSelector(
  (state) => state.analysisByProduct,
  (analysisByProduct) => analysisByProduct
);

export const availableDatesSelector = createSelector(
  rootSelector,
  (analysisByProduct) => analysisByProduct.availableDates
);

export const dailyPerformanceSelector = createSelector(
  rootSelector,
  (analysisByProduct) => analysisByProduct.dailyPerformance
);

export const weeklyPerformanceSelector = createSelector(
  rootSelector,
  (analysisByProduct) => analysisByProduct.weeklyPerformance
);

export const monthlyPerformanceSelector = createSelector(
  rootSelector,
  (analysisByProduct) => analysisByProduct.monthlyPerformance
);

export const searchProductsSelector = createSelector(
  rootSelector,
  (analysisByProduct) => analysisByProduct.searchProducts
);

export const productSelector = createSelector(
  rootSelector,
  (analysisByProduct) => analysisByProduct.product
);
