import { createSlice } from '@reduxjs/toolkit';
import {
  getAvailableDates,
  getDailyPerformance,
  getMonthlyPerformance,
  getProduct,
  getWeeklyPerformance,
  searchProducts,
} from './actions';

const initialState = {
  monthlyPerformance: {
    error: '',
    isLoading: false,
    data: undefined,
  },
  weeklyPerformance: {
    error: '',
    isLoading: false,
    data: undefined,
  },
  dailyPerformance: {
    error: '',
    isLoading: false,
    data: undefined,
  },
  availableDates: {
    monthly: [],
    weekly: [],
    isLoading: false,
  },
  searchProducts: {
    isLoading: false,
    data: [],
  },
  product: {
    isLoading: false,
    data: undefined,
  },
};

const analysisByProductSlice = createSlice({
  name: 'analysis_by_product',
  initialState,
  reducers: {
    setDailyPerformance: (state, action) => {
      state.dailyPerformance.data = action.payload;
    },
    setWeeklyPerformance: (state, action) => {
      state.weeklyPerformance.data = action.payload;
    },
    setMonthlyPerformance: (state, action) => {
      state.monthlyPerformance.data = action.payload;
    },
    clearProducts: (state) => {
      state.searchProducts.data = [];
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Get Available Dates
    builder.addCase(getAvailableDates.pending, (state) => {
      state.availableDates.isLoading = true;
    });
    builder.addCase(getAvailableDates.fulfilled, (state, action) => {
      state.availableDates.isLoading = false;
      state.availableDates.monthly = action.payload?.data?.monthly;
      state.availableDates.weekly = action.payload?.data?.weekly;
    });
    builder.addCase(getAvailableDates.rejected, (state, action) => {
      state.availableDates.isLoading = false;
      state.availableDates.error = action.payload;
    });

    // Get Daily Performance
    builder.addCase(getDailyPerformance.pending, (state) => {
      state.dailyPerformance.isLoading = true;
      state.dailyPerformance.error = '';
    });
    builder.addCase(getDailyPerformance.fulfilled, (state, action) => {
      state.dailyPerformance.isLoading = false;
      state.dailyPerformance.data = action.payload.data;
    });
    builder.addCase(getDailyPerformance.rejected, (state, action) => {
      state.dailyPerformance.isLoading = false;
      state.dailyPerformance.error = action.payload;
    });

    // Get Weekly Performance
    builder.addCase(getWeeklyPerformance.pending, (state) => {
      state.weeklyPerformance.isLoading = true;
      state.weeklyPerformance.error = '';
    });
    builder.addCase(getWeeklyPerformance.fulfilled, (state, action) => {
      state.weeklyPerformance.isLoading = false;
      state.weeklyPerformance.data = action.payload.data;
    });
    builder.addCase(getWeeklyPerformance.rejected, (state, action) => {
      state.weeklyPerformance.isLoading = false;
      state.weeklyPerformance.error = action.payload;
    });

    // Get Monthly Performance
    builder.addCase(getMonthlyPerformance.pending, (state) => {
      state.monthlyPerformance.isLoading = true;
      state.monthlyPerformance.error = '';
    });
    builder.addCase(getMonthlyPerformance.fulfilled, (state, action) => {
      state.monthlyPerformance.isLoading = false;
      state.monthlyPerformance.data = action.payload.data;
    });
    builder.addCase(getMonthlyPerformance.rejected, (state, action) => {
      state.monthlyPerformance.isLoading = false;
      state.monthlyPerformance.error = action.payload;
    });

    // Search Products
    builder.addCase(searchProducts.pending, (state) => {
      state.searchProducts.isLoading = true;
    });
    builder.addCase(searchProducts.fulfilled, (state, action) => {
      state.searchProducts.isLoading = false;
      state.searchProducts.data = action.payload.data;
    });
    builder.addCase(searchProducts.rejected, (state, action) => {
      state.searchProducts.isLoading = false;
      state.searchProducts.error = action.payload;
    });

    // Get Product
    builder.addCase(getProduct.pending, (state) => {
      state.product.isLoading = true;
      state.product.data = undefined;
    });
    builder.addCase(getProduct.fulfilled, (state, action) => {
      state.product.isLoading = false;
      state.product.data = action.payload.data;
    });
    builder.addCase(getProduct.rejected, (state, action) => {
      state.product.isLoading = false;
      state.product.error = action.payload;
    });
  },
});

export const actions = {
  ...analysisByProductSlice.actions,
  getAvailableDates,
  getDailyPerformance,
  getWeeklyPerformance,
  getMonthlyPerformance,
  searchProducts,
  getProduct,
};

export * as selectors from './selectors';

export default analysisByProductSlice.reducer;
