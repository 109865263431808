import { createSelector } from '@reduxjs/toolkit';

const rootSelector = createSelector(
  (state) => state.reportSettings,
  (reportSettings) => reportSettings
);

export const dataSelector = createSelector(rootSelector, (reportSettings) => reportSettings.data);

export const isLoadingSelector = createSelector(
  rootSelector,
  (reportSettings) => reportSettings.isLoading
);

export const errorSelector = createSelector(rootSelector, (reportSettings) => reportSettings.error);

export const successSelector = createSelector(
  rootSelector,
  (reportSettings) => reportSettings.success
);

export const fiscalCalendarSelector = createSelector(
  rootSelector,
  (reportSettings) => reportSettings.fiscalCalendar
);
