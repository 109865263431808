import { createSelector } from '@reduxjs/toolkit';

const rootSelector = createSelector(
  (state) => state.budget,
  (budget) => budget
);

export const isLoadingSelector = createSelector(rootSelector, (budget) => budget.isLoading);

export const userBudgetSelector = createSelector(rootSelector, (budget) => budget.userBudget);

export const userBudgetError = createSelector(rootSelector, (budget) => budget.error);
