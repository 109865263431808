import { createAsyncThunk } from '@reduxjs/toolkit';
import * as analyticsService from 'services/analytics.service';

export const getAvailableDates = createAsyncThunk(
  'dashboard/getAvailableDates',
  async (payload, thunkAPI) => {
    try {
      const result = await analyticsService.getAvailableDates();

      return thunkAPI.fulfillWithValue({ data: result?.data });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getDailyPerformance = createAsyncThunk(
  'dashboard/getDailyPerformance',
  async (payload, thunkAPI) => {
    try {
      const result = await analyticsService.getPerformances({ ...payload, period: 'daily' });

      return thunkAPI.fulfillWithValue({ data: result?.data });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getWeeklyPerformance = createAsyncThunk(
  'dashboard/getWeeklyPerformance',
  async (payload, thunkAPI) => {
    try {
      const result = await analyticsService.getPerformances({ ...payload, period: 'weekly' });

      return thunkAPI.fulfillWithValue({ data: result?.data });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getMonthlyPerformance = createAsyncThunk(
  'dashboard/getMonthlyPerformance',
  async (payload, thunkAPI) => {
    try {
      const result = await analyticsService.getPerformances({ ...payload, period: 'monthly' });

      return thunkAPI.fulfillWithValue({ data: result?.data });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getGraphPerformance = createAsyncThunk(
  'dashboard/getGraphPerformance',
  async (payload, thunkAPI) => {
    try {
      const result = await analyticsService.getGraphPerformances(payload);

      return thunkAPI.fulfillWithValue({ data: result?.data });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getTopSalesProducts = createAsyncThunk(
  'dashboard/getTopSalesProducts',
  async (payload, thunkAPI) => {
    try {
      const result = await analyticsService.getProductRanks({
        ...payload,
        type: 'top-sales',
      });

      return thunkAPI.fulfillWithValue({ data: result?.data });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getTopTrendingProducts = createAsyncThunk(
  'dashboard/getTopTrendingProducts',
  async (payload, thunkAPI) => {
    try {
      const result = await analyticsService.getProductRanks({
        ...payload,
        type: 'trending-sales',
      });

      return thunkAPI.fulfillWithValue({ data: result?.data });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getTopWorstProducts = createAsyncThunk(
  'dashboard/getTopWorstProducts',
  async (payload, thunkAPI) => {
    try {
      const result = await analyticsService.getProductRanks({
        ...payload,
        type: 'worst-sales',
      });

      return thunkAPI.fulfillWithValue({ data: result?.data });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);
