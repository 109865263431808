import i18next from 'i18next';

export const getRakutenImportProductError = (error) => {
  let message = '';

  if (error?.response?.data?.error) {
    const errorType = error.response.data.error;
    switch (errorType) {
      case 'channel_not_found':
        message = i18next.t('pages.rakuten_import.missing_ec');
        break;
      case 'empty_file':
        message = i18next.t('pages.rakuten_import.empty_file');
        break;
      case 'invalid_date_in_file_name':
        message = i18next.t('pages.rakuten_import.invalid_file_name');
        break;
    }
  }

  return message;
};
