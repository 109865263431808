import { createAsyncThunk } from '@reduxjs/toolkit';
import { actions as profileActions } from 'modules/profile';
import * as authService from 'services/auth.service';
import * as profileService from 'services/profile.service';
import { isAxiosError, setAccessToken } from 'utils/api';
import { getAuthError, getForgotPasswordError, loadStandardUserResources } from './utils';

export const login = createAsyncThunk('auth/login', async (payload, thunkAPI) => {
  try {
    const shopifyShop = localStorage.getItem('shop') ?? '';

    // remove residual properties that may have left behind during logout
    localStorage.clear();

    const { access_token, refresh_token } = await authService.login(payload);

    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
    localStorage.setItem('shop', shopifyShop);

    setAccessToken(access_token);
  } catch (error) {
    let errorMessage = error?.message;
    if (error?.response) {
      errorMessage = getAuthError(error);
    }
    return thunkAPI.rejectWithValue(errorMessage);
  }

  try {
    const profile = await profileService.getProfile();
    await thunkAPI.dispatch(profileActions.setProfileData(profile.data));

    // retrieve standard data
    if (profile.data?.role?.toLowerCase() === 'standard') {
      await loadStandardUserResources(thunkAPI);
    }
  } catch (error) {
    setAccessToken('');

    localStorage.clear();

    return thunkAPI.rejectWithValue(error?.message);
  }

  return thunkAPI.fulfillWithValue();
});

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  try {
    await authService.logout();

    thunkAPI.dispatch(profileActions.setProfileData(undefined));

    setAccessToken('');

    localStorage.clear();

    return thunkAPI.fulfillWithValue();
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.message);
  }
});

export const forgotPassword = createAsyncThunk('auth/forgotPassword', async (payload, thunkAPI) => {
  try {
    await authService.forgotPassword(payload);

    return thunkAPI.fulfillWithValue();
  } catch (error) {
    let errorMessage = error?.message;

    if (isAxiosError(error)) {
      errorMessage = getForgotPasswordError(error);
    }

    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const resetPassword = createAsyncThunk('auth/resetPassword', async (payload, thunkAPI) => {
  try {
    await authService.resetPassword(payload);

    return thunkAPI.fulfillWithValue();
  } catch (error) {
    let errorMessage = error?.message;

    return thunkAPI.rejectWithValue(errorMessage);
  }
});

export const registerPassword = createAsyncThunk(
  'auth/registerPassword',
  async (payload, thunkAPI) => {
    try {
      await authService.registerPassword(payload);

      return thunkAPI.fulfillWithValue();
    } catch (error) {
      let errorMessage = error?.message;

      return thunkAPI.rejectWithValue(errorMessage);
    }
  }
);
