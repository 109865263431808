import { createAsyncThunk } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import * as analyticsService from 'services/analytics.service';
import * as storeService from 'services/store.service';

export const getAvailableDates = createAsyncThunk(
  'analysis_by_channel/getAvailableDates',
  async (payload, thunkAPI) => {
    try {
      const result = await analyticsService.getAvailableDates({
        ...payload,
        period: 'monthly',
      });

      return thunkAPI.fulfillWithValue({ data: result?.data });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getAnalysisByChannel = createAsyncThunk(
  'analysis_by_channel/getAnalysisByChannel',
  async (payload, thunkAPI) => {
    try {
      const now = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
      const elevenMonthsAgo = dayjs(now)
        .startOf('month')
        .subtract(11, 'months')
        .format('YYYY-MM-DD');

      const { data } = await analyticsService.getAnalysisByChannel({
        ...payload,
        from: elevenMonthsAgo,
        to: now,
      });

      return thunkAPI.fulfillWithValue({ data });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getStoreProductGroups = createAsyncThunk(
  'analysis_by_channel/getStoreProductGroups',
  async (payload, thunkAPI) => {
    try {
      const { id } = payload;
      const { data } = await storeService.getProductGroups(id);

      return thunkAPI.fulfillWithValue({ data });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getTopSalesProducts = createAsyncThunk(
  'analysis_by_channel/getTopSalesProducts',
  async (payload, thunkAPI) => {
    try {
      const result = await analyticsService.getProductRanks({
        ...payload,
        purpose: 'overall',
        type: 'top-sales',
      });

      return thunkAPI.fulfillWithValue({ data: result?.data });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getTopTrendingProducts = createAsyncThunk(
  'analysis_by_channel/getTopTrendingProducts',
  async (payload, thunkAPI) => {
    try {
      const result = await analyticsService.getProductRanks({
        ...payload,
        purpose: 'overall',
        type: 'trending-sales',
      });

      return thunkAPI.fulfillWithValue({ data: result?.data });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);

export const getTopWorstProducts = createAsyncThunk(
  'analysis_by_channel/getTopWorstProducts',
  async (payload, thunkAPI) => {
    try {
      const result = await analyticsService.getProductRanks({
        ...payload,
        purpose: 'overall',
        type: 'worst-sales',
      });

      return thunkAPI.fulfillWithValue({ data: result?.data });
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  }
);
