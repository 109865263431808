import { createSlice } from '@reduxjs/toolkit';
import {
  deleteProductMasterList,
  exportData,
  fetchProductMaster,
  importData,
  isLinkChannel,
  resetLink,
} from './actions';

const initialState = {
  isAuth: false,
  isLoading: false,
  isErrorImport: false,
  meta: undefined,
  exportErrorResponse: '',
  isLink: '',
  success: '',
  importResponse: '',
  exportDataResponse: '',
  fetchProductMasterError: '',
  fetchProductMasterList: '',
  error: '',
  list: [],
};

const productMasterSlice = createSlice({
  name: 'productMaster',
  initialState,
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Fect product master data list
    builder.addCase(fetchProductMaster.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchProductMaster.fulfilled, (state, action) => {
      state.isLoading = false;
      state.list = action.payload.list;
      state.fetchProductMasterList = action.payload;
      state.meta = action.payload.meta;
    });
    builder.addCase(fetchProductMaster.rejected, (state, action) => {
      state.isLoading = false;
      state.fetchProductMasterError = action.payload;
    });

    // Export
    builder.addCase(exportData.pending, (state) => {
      state.isLoading = true;
      state.exportDataResponse = '';
      state.exportErrorResponse = '';
    });
    builder.addCase(exportData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.exportDataResponse = action.payload;
      state.exportErrorResponse = '';
    });
    builder.addCase(exportData.rejected, (state, action) => {
      state.isLoading = false;
      state.exportErrorResponse = action.payload;
    });

    // Import
    builder.addCase(importData.pending, (state) => {
      state.isLoading = true;
      state.isErrorImport = false;
      state.importResponse = '';
    });
    builder.addCase(importData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isErrorImport = false;
      state.importResponse = action.payload;
    });
    builder.addCase(importData.rejected, (state) => {
      state.isLoading = false;
      state.isErrorImport = true;
    });

    // is link channel
    builder.addCase(isLinkChannel.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(isLinkChannel.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.isLink = action.payload;
    });
    builder.addCase(isLinkChannel.rejected, (state) => {
      state.isLoading = false;
    });

    // Deletes data
    builder.addCase(deleteProductMasterList.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(deleteProductMasterList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = '';
      state.success = action.payload;
    });
    builder.addCase(deleteProductMasterList.rejected, (state) => {
      state.isLoading = false;
    });

    // resetLink
    builder.addCase(resetLink.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });
    builder.addCase(resetLink.fulfilled, (state) => {
      state.isLoading = false;
      state.isLink = '';
    });
    builder.addCase(resetLink.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default productMasterSlice.reducer;

export const actions = {
  ...productMasterSlice.actions,
  importData,
  exportData,
  fetchProductMaster,
  isLinkChannel,
  deleteProductMasterList,
  resetLink,
};

export * as selectors from './selectors';
