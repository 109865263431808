import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { forgotPassword, login, logout, registerPassword, resetPassword } from './actions';

const initialState = {
  isAuth: false,
  isLoading: false,
  error: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    // cases
    builder.addCase(login.fulfilled, (state) => {
      state.isLoading = false;
      state.isAuth = true;
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(resetPassword.pending, (state) => {
      state.isLoading = true;
      state.error = '';
    });

    // logout
    builder.addCase(logout.fulfilled, (state) => {
      state.isAuth = false;
    });

    // matchers
    builder.addMatcher(
      isAnyOf(login.pending, forgotPassword.pending, registerPassword.pending),
      (state) => {
        state.isLoading = true;
        state.error = '';
      }
    );
    builder.addMatcher(isAnyOf(forgotPassword.fulfilled, registerPassword.fulfilled), (state) => {
      state.isLoading = false;
    });
    builder.addMatcher(
      isAnyOf(login.rejected, forgotPassword.rejected, registerPassword.rejected),
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      }
    );
  },
});

export const actions = {
  ...authSlice.actions,
  login,
  logout,
  registerPassword,
  resetPassword,
  forgotPassword,
};

export * as selectors from './selectors';

export default authSlice.reducer;
