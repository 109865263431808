import i18next from 'i18next';
import { actions as dashboardActions } from 'modules/dashboard';
import { actions as storeActions } from 'modules/ec_channel';
import { actions as productGroupActions } from 'modules/product_group';
import { actions as reportSettingsActions } from 'modules/report_settings';
import * as reportSettingsService from 'services/reportSettings.service';
import * as storeService from 'services/store.service';
import FiscalCalendar from 'utils/fiscalCalendar';
import isNumeric from 'utils/isNumeric';

export const getAuthError = (error) => {
  let message = '';
  let remainingAttempt = 0;

  if (error?.response?.data?.error) {
    const errorType = error.response.data.error;
    switch (errorType) {
      case 'invalid_client':
        message = i18next.t('errors.server');
        break;
      case 'user_locked':
        message = i18next.t('errors.login.user_locked');
        break;
      case 'user_suspended':
        message = i18next.t('errors.login.user_suspended');
        break;
      case 'user_pending':
        message = i18next.t('errors.login.user_pending');
        break;
      case 'invalid_user_credentials':
        message = i18next.t('errors.login.user_pending');
        break;
      case 'invalid_password': {
        const hint = error?.response?.data?.hint?.split(' ');
        remainingAttempt =
          hint?.length && isNumeric(hint[hint.length - 1]) ? +hint[hint.length - 1] : 0;
        if (remainingAttempt) {
          message = i18next.t('errors.login.failed_attempt', { remainingAttempt });
        } else {
          message = i18next.t('errors.login.invalid_user_password');
        }
        break;
      }
    }
  }

  return message;
};

export const getForgotPasswordError = (error) => {
  let message = '';

  if (error?.response?.status === 404) {
    message = i18next.t('errors.forgot_password.user_not_found');
  }

  return message;
};

export const loadStandardUserResources = async (thunkAPI) => {
  const reportSettings = await reportSettingsService.getReportSettings();
  const fiscalMonth = reportSettings.data.fiscal_month;
  const fiscalDay = reportSettings.data.fiscal_day;
  const fiscalYear = FiscalCalendar.getFiscalYearFromSettings(fiscalMonth);
  const fiscalCalendar = new FiscalCalendar(fiscalYear, fiscalMonth, fiscalDay);
  const stores = await storeService.getStores();

  return Promise.all([
    thunkAPI.dispatch(dashboardActions.reset),
    thunkAPI.dispatch(reportSettingsActions.setReportSettingsData(reportSettings.data)),
    thunkAPI.dispatch(
      reportSettingsActions.setFiscalCalendar({
        start: fiscalCalendar.start,
        end: fiscalCalendar.end,
      })
    ),
    thunkAPI.dispatch(storeActions.setStores(stores.data)),
    thunkAPI.dispatch(productGroupActions.fetchProductGroup()),
  ]);
};
