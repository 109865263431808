const ja = {
  translation: {
    // define translations below
    form: {
      required: 'この項目は必須です。',
      email: {
        required: 'メールアドレスを入力してください。',
        invalid: 'メールアドレスが不正です。',
        invalid_format: 'メールアドレスの形式で入力して下さい。',
        not_found: 'ご入力いただいたメールアドレスが見つかりません。',
        already_exist: 'このメールアドレスは既に登録されています。',
        invalid_length: '5～50文字で入力してください。',
        special_characters: '使用できない文字が含まれています。',
      },
      password: {
        minLength: '8～25文字で入力してください。',
        maxLength: '8～25文字で入力してください。',
        noSpace: 'スペースを入力しないでください。',
        confirm: '再入力されたパスワードが一致しません。',
        strong:
          'パスワードには、大文字1文字、特殊文字1文字、および少なくとも8文字が含まれている必要があります。',
        required: 'パスワードを入力してください。',
      },
      username: {
        noSpace: 'スペースを入力しないでください。',
        alpha: '特殊文字を入力しないでください。',
        alpha_only: '特殊文字は使用しないでください。',
        alphanumeric: '大文字アルファベット(半角)と数字(半角)を使用してください。',
        uppercase_only: '大文字で入力してください。',
        required: 'IDを入力してください。',
        already_exist: 'このIDは既に使われています。',
      },
      shopUrl: {
        invalid: '有効な myshopify.com ドメインを入力してください。',
      },
      first_name: {
        required: 'お名前を入力してください。',
      },
      lastName: {
        required: 'お名前を入力してください。',
      },
      name: {
        invalid_length: '5～20文字で入力してください。',
      },
      network_error: 'ネットワークエラーが発生しました。',
    },
    labels: {
      app: 'Cross Analytics',
      first_name: '名',
      first_name_placeholder: '名を入力してください。',
      last_name: '姓',
      last_name_placeholder: '姓を入力してください。',
      login: 'ログイン',
      signup: 'サインアップ',
      remember_me: '私を覚えてますか',
      forgot_password: 'パスワードを忘れた場合は、<Link>こちら</Link>から再設定してください。',
      username: 'ID',
      username_placeholder: 'IDを入力してください。',
      email_address: 'メールアドレス',
      confirm_password: '新しいパスワード（確認）',
      update_password: 'パスワードを更新',
      submit: '送信',
      register: '登録',
      update: 'アップデート',
      save: '保存する',
      add_new: '追加',
      reset_password: 'パスワードを再設定する',
      new_password: '新しいパスワード',
      confirm_new_password: '新しいパスワードを確認',
      enter_keyword: '検索',
      get_started: 'はじめましょう',
      integrations: '統合',
      settings: '設定',
      documentation: 'ドキュメンテーション',
      fullname: 'フルネーム',
      inquiry_content: 'お問合わせ内容',
      navigation: 'ナビゲーション',
      resources: 'リソース',
      cancel: 'キャンセル',
      action: 'アクション',
      close: '閉じる',
      admin: '管理者',
      superadmin: 'スーパー管理者',
      standard: '一般ユーザー',
      user_type: 'ユーザータイプ',
      select: '選択してください。',
      ok: 'OK',
      success: '成功',
      error: 'エラー',
      usage_heading: '使い方',
      all_channels: '全チャネル',
      ec: '自社EC',
      shopify: 'Shopify',
      rakuten: '楽天',
      amazon: 'Amazon',
      yahoo: 'Yahoo!ショッピング',
      help: 'Help',
      login_form: {
        username: 'IDを入力してください',
        password: 'パスワードを入力してください',
      },
      delete_confirmation_modal: {
        submit: 'はい',
      },
      select_product_group: '商品グループを選択',
      select_month: '月を選択',
      select_week: '週を選択',
      select_date_aggregation: '集計日を選択',
      select_month_aggregation: '集計月を選択',
      select_week_aggregation: '集計週を選択',
      yesterday: '対前日',
      last_week: '対前週',
      last_month: '対前月',
      last_year: '対前年',

      // table components
      sales: '売上',
      sales_with_sign: '売上 ({{sign}})',
      number_of_access: 'アクセス数',
      purchase_rate: '購入率',
      order_unit_price: '注文単価',

      day: 'Day',
      week: '前週',
      month: '先月',
      year: '昨年',

      daily: '日次',
      weekly: '週次',
      monthly: '月次',

      // list items
      volume: '数量',
      average_sales_per_unit: '平均販売単価',
      compared_yesterday: '昨日比',
      compared_last_week: '前週比',
      compared_last_month: '先月比',
      compared_last_year: '昨年比',
      average_ratio: '平均比',

      search_products: '商品を検索',
      no_products: '商品がありません。',
    },
    table_columns: {
      product_name: '商品名',
      sales: '売上',
      sales_mix_ratio: '売上構成比',
      sales_mix_ratio_total: '売上構成比 (累計)',
      sales_mix_ratio_channel: '売上占有率\n({{channel}})',
      number_of_views: 'アクセス数',
      purchase_rate: '購入率',
      budget_progress: '予算進捗',
      budget_ratio: '予算比({{sign}})',

      compared_last_period: '{{period}}比({{sign}})',
      compared_last_month: '前月比 ({{sign}})',
    },
    errors: {
      server: '何か問題が発生しました。',
      api_error: 'APIエラーが発生したためページを再読み込みしてください',
      login: {
        user_locked: 'アカウントは停止されています。詳細については管理者に問い合わせてください。',
        user_suspended: 'アカウントは停止されています。詳細は管理者にお問い合わせください。',
        user_pending: 'メールアドレスがまだ認証されていないためログインできません。',
        invalid_user_credentials: 'IDまたはパスワードが間違っています。',
        invalid_user_password: 'IDまたはパスワードが間違っています。',
        failed_attempt:
          'IDまたはパスワードが間違っています。ログインにもう{{remainingAttempt}}回失敗すると、60分間アカウントがロックされます。',
      },
      forgot_password: {
        user_not_found: 'メールアドレスが見つかりません。',
      },
      ecSettings: {
        shop_not_found: 'ショップが見つかりません。',
      },
      no_linked_store: '連携されたECサイトはありません。',
    },
    pages: {
      signup: {
        agree_to_terms: '[サインアップ]をクリックすると、読んだことに同意したことになります',
        signup_complete:
          '確認メールが受信トレイに送信されました。 リンクをクリックして、登録プロセスを完了します。',
        terms_conditions: '規約と条件',
        create_free_account: '無料アカウントを作成する',
      },
      forgot_password: {
        title: 'パスワードの再設定',
        sub_title:
          'ご入力いただいたメールアドレスにパスワードをリセットするためのページへのリンクをお送りします。',
        sub_heading: 'アカウントを復旧するには、以下にメールアドレスを入力してください。',
        success_heading: 'パスワード再設定用のメールを送信いたしました。',
        success_sub_heading:
          'お送りしたメールに記載された指示に従ってパスワードの再設定を行ってください。',
      },
      reset_password: {
        sub_heading: '新しいパスワードを入力してください。',
        success: 'パスワードは正常に更新されました。',
        card_title: 'パスワードの再設定',
        input1_label: '一時的なパスワード',
        input1_placeholder: '一時的なパスワードを入力してください。',
        input2_label: '新しいパスワード',
        input2_placeholder: '新しいパスワードを入力してください。',
        input3_placeholder: '新しいパスワードを再入力してください。',
        validation_msg: {
          label1: 'スペースを含めないでください。',
          label2: '半角英数字8～25文字で入力してください。',
          label3: '大文字を1文字以上入力してください。',
        },
        error_msg: {
          incorrect_temp_pw: '一時的なパスワードが間違っています。',
          temp_pw_required: '一時的なパスワードを入力してください。',
          new_pw_required: '新しいパスワードを入力してください。',
          confirm_pw_required: '新しいパスワードが必要です。',
          confirm_pw_reenter: '再入力されたパスワードが一致しません。',
          pw_contains_whitespace: 'Do not include spaces',
          pw_without_lowercase: 'Please input at least 1 uppercase letter',
          pw_requirement_not_met: 'パスワードと確認用パスワードが一致しません。確認してください。',
        },
        modal: {
          update_pw_success: 'パスワードの更新が完了しました！',
          redirect_to_login: 'ログイン画面へ',
        },
      },
      register_password: {
        card_title: 'パスワード',
        form: {
          temporary_password: '一時的なパスワード',
          temporary_password_placeholder: '一時的なパスワードを入力してください。',
          error_message: {
            password_required: '新しいパスワードを入力してください。',
          },
        },
        submit_btn: '送信',
        modal: {
          update_pw_success: '作成されたパスワード',
          redirect_to_login: 'ログイン画面へ',
        },
      },
      users: {
        user_created: 'ユーザーが作成されました。',
        user_updated: 'ユーザーの詳細が更新されました。',
        user_deleted: 'ユーザーが削除されました',
        add_user: 'ユーザーを追加する',
        edit_user: 'ユーザー編集',
        delete_user: 'ユーザーを削除',
        first_name: 'ファーストネーム',
        last_name: '苗字',
        email_address: '電子メールアドレス',
        status: '状態',
        delete_confirmation: '選択したユーザーを削除してもよろしいですか？',
      },
      activate: {
        heading: 'アカウントをアクティブ化',
        subtitle: 'アカウントをアクティブ化するためのパスワードを設定します。',
        activated: 'アカウントが有効になりました。 これで、アカウントにログインできます。',
      },
      dashboard: {
        title: 'ダッシュボード',
        periodic_performance: '{{period}}パフォーマンス',
        by_channel: 'チャネル別',
        total_channels: '全チャネル計',
        sales: '売上',
        budget_progress: '予算進捗',
        budget_ratio: '予算比({{sign}})',
        compared_last_week: '前週比({{sign}})',
        compared_last_month: '先月比({{sign}})',
        compared_last_year: '昨年比({{sign}})',
        compared_budget: '予算比({{sign}})',
        budget: '予算',
        sales_last_year: '昨年度売上',

        trend: 'トレンド',
        daily: '日次',
        weekly: '週次',
        monthly: '月次',
        aggregation: '選択期間集計',
        elapsed_compared_budget: '経過日数予算比 ({{sign}})',
        elapsed_compared_last_year: '経過日数昨年比 ({{sign}})',
        sales_top: '売上Top{{rankingCount}}商品',
        yesterday: '対前日',
        last_week: '対前週',
        last_month: '対前月',
        last_year: '対前年',
        change_month: '',

        top_sales_products: '売上Top{{count}}商品',
        top_trending_products: '売上上昇 Top {{count}}',
        top_worst_products: '売上下降 Worst {{count}}',
      },
      ec_settings: {
        main_heading: 'ECサイト連携',
        sub_heading: 'Cross Analyticsと連携するECサイトを選択してください。',
        store: 'のストア',
        ec_site_name: 'ECサイト名',
        store_name: '店舗名',
        created_at: '連携日',
        delete_confirmation: '削除の確認',
        delete_confirmation_detail: '選択されたデータを本当に削除しますか？',
        deletion: '削除',
        link_success: 'との連携に成功しました。',
        queue_display_message:
          '現在1年分のデータを取得しています。レポートに反映されるまで、しばらくお待ちください。',
        shopify: {
          shop_url: 'ショップURL',
          shop_url_required: 'URL は必須入力フィールドです。',
          approval: '承認',
          approval_heading: 'Cross AnalyticsがあなたのShopifyストアと接続することを許可しますか？',
          duplicate_store: 'Shopifyストアはすでにリンクされています。',
          callback_success: 'Shopifyストアのリンクに成功しました。',
          callback_error: 'Shopifyストアのリンクにエラーが発生しました。',
          failed_to_connect_to_shopify: 'Shopifyストアのリンクにエラーが発生しました。',
          failed_to_queue_old_data: 'Shopifyからの古いデータの取得に失敗しました。',
        },
        rakuten: {
          link_success: 'Rakutenストアのリンクに成功しまÍした。',
          link_error: 'Rakutenストアのリンクにエラーが発生しました。',
          invalid_details: '楽天ショップの詳細が無効です。',
          duplicate_store: '楽天店はすでにリンク済み。',
          service_secret: '楽天サービスシークレット',
          license_key: '楽天ライセンスキー',
          failed_to_queue_old_data: 'Rakutenからの古いデータの取得に失敗しました。',
        },
        amazon: {
          approval: '承認',
          approval_heading: 'Cross AnalyticsがあなたのAmazonストアと接続することを許可しますか？',
          duplicate_store: 'Amazonストアはすでにリンクされています。',
          callback_success: 'Amazonストアのリンクに成功しました。',
          failed_to_connect_to_amazon: 'Amazonストアのリンクにエラーが発生しました。',
          failed_to_queue_old_data: 'Amazonからの古いデータの取得に失敗しました。',
        },
        // shopify_linking_success: 'shopifyストアのリンクに成功しました。',
        // shopify_linking_failed: 'shopifyストアのリンクにエラーが発生しました。',
        // rakuten_linking_success: '楽天ショップのリンクに成功しました。',
        // rakuten_linking_failed: '楽天ストアのリンクにエラーが発生しました。',
      },
      rakuten_import: {
        title: '楽天RMSインポート',
        missing_ec: '連携されたECサイトはありません。',
        import_success: '合計{{rows}}個の商品情報のインポートが完了しました。',
        empty_file: '空のCSVファイルをアップロードすることはできません。',
        failed_rows: '以下のエラーにより、{{rows}}行をインポートできませんでした。',
        failed_row: '{{row}}行目: {{error}}',
        import_products: 'クリックして楽天の売上をアップロード',
        import_product_views: 'クリックして楽天のアクセス数をアップロード',
        invalid_file_name: '正しい形式のファイル名を使用して、もう一度アップロードしてください。',
        column_empty: '{{column}}列のセルが空白です。',
        column_not_exists: 'まだデータベース上に存在しないため、の商品を作成できません。',
        select_channel: 'チャンネルを選択します。',
        no_rakuten_linked:
          'ドロップダウンメニューにデータを表示するには、楽天ECサイトを連携してください。',
      },
      not_found: {
        title: 'ページが見つかりません',
        sub_heading: 'お探しのページは削除されたか、別の場所へ移動した可能性があります。',
        back_to_top: 'トップページへ戻る',
      },
      faq: {
        heading: 'よくあるご質問',
        sub_heading: 'お客様からお問い合わせいただく質問をQ&A形式でまとめました。',
      },
      inquiry: {
        heading: 'お問い合わせ',
        sub_heading: 'このままお問い合わせされる方は下記のフォームにご入力ください。',
        success_message: 'お問い合わせを送信しました。',
        failed_message: '送信中にエラーが発生しました。',
      },
      profile: {
        heading: 'プロファイル編集',
        sub_heading: 'アカウント情報を更新します。',
        success_message: '詳細が正常に更新されました。',
        failed_message: '更新に失敗しました。',
      },
      landing: {
        main_heading: 'React Base Templateへようこそ！',
        sub_heading: 'Reactプロジェクトの開発に関する軽量の定型文。',
        why_heading: 'なぜベーステンプレートを使用するのですか？',
        docker: {
          heading: '柔軟な環境',
          description:
            '「自分のマシンで動作する」という問題を完全に解消します。 環境のセットアップ、環境固有の問題のデバッグ、およびより移植性が高くセットアップが簡単なコードベースに費やす時間を短縮します。',
        },
        react: {
          heading: '高速で直感的なUI',
          description:
            'ReactJSは非常に直感的に操作でき、UIのレイアウトに双方向性を提供します。 これらのコンポーネントを利用して1つの場所に統合できるように、構成可能です。 したがって、コードははるかに保守可能で柔軟になります。',
        },
        laravel: {
          heading: '強力なAPI',
          description:
            'LaravelのAPI機能を利用してバックエンドAPIを簡単に開発できます。 サードパーティの統合とライブラリが簡単で、すばやく簡単です。',
        },
        our_customers_heading: 'お客様',
        reviews_heading: '私たちのクライアントが言うこと',
        see_all_reviews: 'すべてのレビューを見る',
        call_to_action: '今すぐアプリケーション開発を加速しましょう!',
      },
      product_master: {
        usage_heading: '使い方',
        title: '登録商品リスト',
        import_text: '商品マスター登録:',
        export_template_text: '登録用テンプレート:',
        export_latest_list_text: '最新リスト取得:',
        upload: 'アップロード',
        download: 'ダウンロード',
        no_link_site: '連携されたECサイトはありません。',
        excel_button: {
          menu: 'Excel アップロード',
          upload: '商品マスタをアップロード',
          download: '商品マスタをダウンロード',
          success_export: 'データのエクスポートに成功しました。',
          success_import: 'データのインポートに成功しました。',
        },
      },
      product_group: {
        title: '登録グループリスト',
        import_text: 'グループ登録:',
        export_template_text: '登録用テンプレート:',
        export_latest_list_text: '最新リスト取得:',
        upload: 'アップロード',
        download: 'ダウンロード',
        group_name: 'グループ名',
        no_of_products: '登録商品数',
        product_name: '商品名',
        error_download:
          '製品グループをダウンロードするには、まず製品グループをアップロードする必要があります。',
        invalid_upload:
          'グループ名またはca_idが選択されていないため、{{row}}行目をインポートできません。',
        export_error:
          '商品マスタをダウンロードするためには、まず商品マスタのアップロードが必要です。',
      },
      about: {
        main_heading: '私たちの物語',
        sub_heading:
          '私たちは、私たちが信じる人々のために誇りに思う作品をデザイン、作成、制作するために協力しています。',
        meet_the_team: 'チームに会う',
        team_description:
          '思いやり、独創性、細部へのこだわりは、私たちが設計、製造、販売するすべての製品の基盤です。',
        our_mission: '私たちの使命',
        mission_description:
          '私たちの使命は、ビジネスのトレンドと人々中心の文化と行動を重視する提案を重視する高品質のサービスと製品で、卓越したテクノロジーを広めることです。',
        our_activities: '私たちの活動',
        activities_description: '生計を立てるのに忙しくて、生計を立てることを忘れないでください。',
      },
      user_management: {
        title: 'ユーザーリスト',
        empty_rows: '表示するデータがありません。',
        standard_type: '一般ユーザー',
        admin_type: '管理者',
        super_admin_type: 'スーパー管理者',
        delete_user: '削除の確認',
        delete_confirmation: '選択したユーザーを本当に削除しますか？',
        delete_confirmation_has_ecsites_linked:
          'このアカウントには連携されているECサイトがあります。ユーザーアカウントを削除するとECサイトとの連携も削除されますがよろしいでしょうか?',
        delete_confirmation_no_ecsites_linked: 'ユーザーアカウントを削除しますか？',
        delete_success_heading: '削除が完了しました。',
        delete_success_subheading: 'ユーザーの削除が完了しました。',
        delete_success_has_ecsites_linked: 'ユーザーアカウントとECサイトとの連携が削除されました。',
        delete_success_no_ecsites_linked: 'ユーザーアカウントが削除されました',
      },
      edit_user: {
        title: 'ユーザー情報を編集する',
        empty: '表示するデータがありません。',
        success_heading_old: '新規データが保存されました。',
        success_heading: 'ユーザー情報の更新が完了しました。',
        success_subheading: 'アカウントは正常に更新されました。',
        invalid_user_status: '無効なユーザーステータスです。',
        form: {
          username: 'ID',
          first_name: '名',
          last_name: '姓',
          email: 'メールアドレス',
          type: 'ユーザータイプ',
          status: 'アカウントステータス',
        },
      },
      create_user: {
        title: 'ようこそ！',
        sub_title: 'アカウントを作成しましょう。',
        success_heading: '登録に成功しました。',
        success_subheading:
          'アカウント(アカウントID) はシステムに登録されました。アクティベートのためのEメールが送信されましたのでご確認ください。',
        network_error: 'ネットワークエラーが発生しました。',
      },
      budget_management: {
        title: '予算管理',
        set_monthly: '月次で設定',
        set_weekly: '週次で設定',
        set_daily: '日次で設定',
        select_channel: 'チャネルを選択',
        select_year: '年度を選択',
        select_month: '月を選択',
        unit_instructions: '※千単位/百万単位等ではなく、円単位で入力してください。',
        currency: '円',
        excel_upload: 'Excel アップロード',
        save: '保存',
        dataSaved: '保存されたデータ',
        errors: {
          noLinkedSites: '連携されたECサイトがありません。',
          selectFiscalAndChannel:
            '設定を保存するためには、チャネルと年度を選択する必要があります。',
          selectValidExcelFile: '有効なExcelファイルを選択してください。',
          failed_to_save: 'データ保存に失敗しました。',
          out_of_budget_range: 'この項目の値は、0～999,999,999のみとします',
          selectChannelAndFiscalYear: 'チャネルと年度を選択してください。',
          noDataFound: '選択した店舗と一致するデータがありません。',
          importErrorMonthly:
            'インポートに失敗しました。月次予算の{{row}}行目のデータが不完全です。',
          importErrorWeekly: 'インポートに失敗しました。週予算の{{row}}行目のデータが不完全です。',
          importErrorDaily: 'インポートに失敗しました。日計表の{{row}}行目のデータが不完全です。',
          dateOutofRange:
            'アップロードされたExcel内のデータに、選択された年度の範囲外のものが含まれています。',
        },
      },
      report_settings: {
        title: '設定',
        sales_basis: '売上集計ベース',
        consumption_tax: '消費税',
        orders: '受注',
        sales: '出荷',
        tax_included: '税込',
        tax_not_included: '税別',
        term_settings: '期間設定',
        fiscal_month: '年度開始月',
        fiscal_day: '週開始曜日',
        last_day: '月末日',
        switch_weeks_yes: '月の末日で週を切り替える',
        switch_weeks_no: '月の末日で週を切り替えない',
        dashboard_settings: 'ダッシュボード設定',
        number_ranking: 'ランキング表示数',
        product_group: 'デフォルト表示商品グループ',
        save: '保存',
        saving_success: '設定が更新されました。',
        saving_failed: 'エラーが発生しました。',
      },
      account_settings: {
        title: 'アカウント設定',
        userInformation: 'ユーザー情報',
        last_name: '姓',
        first_name: '名',
        name: 'お名前',
        company_name: '会社名',
        email_address: 'メールアドレス',
        edit: '編集',
        cancel: 'キャンセル',
        save: '保存',
        password: 'パスワード',
        change_password: 'パスワードを変更する',
        current_password: '現在のパスワード',
        updated_successfully: '更新が完了しました。',
        email_update_info: 'メールアドレスはユーザーによる確認後に更新されます。',
        enter_current_password: '現在のパスワードを入力してください。',
        email_updated: 'メールアドレスが更新されました。',
        errors: {
          invalidURL: 'URLが無効です。',
          name: {
            required: '名前は必須項目です。',
            min: '3～50文字で入力してください。',
            max: '3～50文字で入力してください。',
          },
          company: {
            required: '会社名を入力してください。',
            min: '3～50文字で入力してください。',
            max: '3～50文字で入力してください。',
          },
          email: {
            required: 'メールは必須項目です。',
            min: '5～50文字で入力してください。',
            max: '5～50文字で入力してください。',
            invalid: '無効な電子メール',
          },
          current_password: {
            required: '現在のパスワードは必須フィールドです。',
            incorrect: 'パスワードが間違っています。',
            min: '8～25文字で入力してください。',
            max: '8～25文字で入力してください。',
          },
          email_taken: 'このメールアドレスは既に使用されています。',
          common: 'エラーが発生しました。',
        },
      },
      terms_of_use: {
        cross_analytics_terms: 'Cross Analytics 利用規約',
        acceptance_of_terms: '規約への同意',
        change_of_terms: '規約の変更',
        prohibitions: '禁止事項',
        date: '[最終改訂: 2023年12月xx日]',
      },
      analysis_by_channel: {
        title: 'チャネル別分析',
        kpi: 'KPIトレンド',
        current_month: '当月',
        last_year_month: '昨年同月',
        last_year: '直近1年',
      },
      analysis_by_product: {
        product_name: '商品名',
        item_no: '品番',
        ASIN: 'ASIN',
        rakuten_no: '楽天品番',

        monthly_performance: '月次パフォーマンス',
        weekly_performance: '週次パフォーマンス',
        daily_performance: '日次パフォーマンス',
      },
    },
    menu: {
      app: 'Cross Analytics',
      home: '家',
      about: '約',
      inquiry: 'お問い合わせ',
      faq: 'よくあるご質問',
      orders: '注文',
      reports: 'レポート',
      integrations: '統合',
      profile: 'プロフィール',
      login: 'ログイン',
      logout: 'ログアウト',
      terms: '利用規約',
      privacy_policy: 'プライバシーポリシー',
      documentation: 'ドキュメンテーション',
      api_reference: 'APIリファレンス',
      styleguide: 'スタイルガイド',

      // sidebar links - menu
      menu: 'Menu',
      dashboard: 'ダッシュボード',
      analysis_by_product: '商品別分析',
      analysis_by_channel: 'チャネル別分析',
      ec: '自社EC',
      amazon: 'Amazon',
      rakuten: '楽天',
      yahoo: 'Yahoo!',

      // sidebar links - settings
      settings: 'Settings',
      budget_management: '予算管理',
      product_master_management: '商品マスタ管理',
      product_group_management: '商品グループ管理',
      report_settings: 'レポート設定',
      account_settings: 'アカウント設定',
      ec_settings: 'ECサイト連携',
      rakuten_import: '楽天RMSインポート',

      // sidebar links - admin
      admin: 'Admin',
      user_search_list: 'ユーザー情報を編集する',
      login_as: 'Login As',
      login_as_activity_log: 'Login As アクティビティログ',

      // sidebar links - support
      support: 'Support',
      help: 'ヘルプ',
      contact_us: 'お問い合わせ',
      feature_request: '機能リクエスト',
      terms_of_use: '利用規約',
    },
    table: {
      no_data: 'データなし。',
    },
  },
};

export default ja;
