import api from 'utils/api';

const fetchProductGroup = async (query) => {
  const { data } = await api.get(`/product/group?${new URLSearchParams(query).toString()}`);

  return data;
};

const fetchProductMaster = async (id) => {
  const req = await api.get(`/product/group/${id}`);
  return req;
};

const exportData = async () => await api.get('/product/group/export-data');

const importProductGroup = async (file) => {
  await api.post(
    '/product/group/import',
    { file: file },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

const deleteProductGroup = async (id) => await api.delete('/product/group/' + id);

export {
  fetchProductGroup,
  fetchProductMaster,
  importProductGroup,
  exportData,
  deleteProductGroup,
};
