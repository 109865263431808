import { createSelector } from '@reduxjs/toolkit';

const rootSelector = createSelector(
  (state) => state.auth,
  (auth) => auth
);

export const isAuthSelector = createSelector(rootSelector, (auth) => auth.isAuth);
export const isLoadingSelector = createSelector(rootSelector, (auth) => auth.isLoading);
export const errorSelector = createSelector(rootSelector, (auth) => auth.error);
