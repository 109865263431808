import api from 'utils/api';

const getBudget = async (params) => {
  const { data } = await api.get(
    `/budget/?fiscalYear=${params.fiscalYear}&userChannelId=${params.userChannelId}`
  );

  return data;
};

const saveBudget = async (payload) => {
  const { data } = await api.post('/budget/create', payload);

  return data;
};

export { saveBudget, getBudget };
