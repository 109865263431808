import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import CutsomParseFormat from 'dayjs/plugin/customParseFormat';
import IsBetween from 'dayjs/plugin/isBetween';
import IsLeapYear from 'dayjs/plugin/isLeapYear';
import IssameOrAfter from 'dayjs/plugin/isSameOrAfter';
import IssameOrBefore from 'dayjs/plugin/isSameOrBefore';
import IsToday from 'dayjs/plugin/isToday';
import LocaleData from 'dayjs/plugin/localeData';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.locale('ja');
dayjs.extend(CutsomParseFormat);
dayjs.extend(LocaleData);
dayjs.extend(IsToday);
dayjs.extend(IsBetween);
dayjs.extend(IsLeapYear);
dayjs.extend(IssameOrAfter);
dayjs.extend(IssameOrBefore);
dayjs.extend(LocalizedFormat);

export const weeksShort = dayjs.weekdaysShort();

export const months = dayjs.months();

export const JAFormats = {
  yearMonths: 'YYYY年M月',
  yearWeeks: 'YYYY年 第W週',
};

export const format = (date, formatString = 'YYYY-MM-DD') => dayjs(date).format(formatString);

export const isAfter = (date, compare) => dayjs(date).isAfter(compare);

export const isBefore = (date, compare) => dayjs(date).isBefore(compare);

export const diff = (from, to, unit = 'M', isFloating = false) =>
  dayjs(to).diff(from, unit, isFloating);

export const isValid = (date, formatString = 'YYYY-MM-DD') =>
  dayjs(date, formatString, true).isValid();

export const isToday = (date) => dayjs(date).isToday();

export const isBetween = (date, start, end, d = '[]') =>
  dayjs(date).isBetween(start, end, 'day', d);

export const isLeapYear = (date) => dayjs(date).isLeapYear();

export const isSameOrAfter = (date, compare) => dayjs(date).isSameOrAfter(compare);

export const isSameOrBefore = (date, compare) => dayjs(date).isSameOrBefore(compare);

export const isSameMonth = (date, compare) => dayjs(date).isSame(compare, 'month');

export const isSameDay = (date, compare) => dayjs(date).isSame(compare, 'day');

export const isSameDate = (date, compare) => dayjs(date).isSame(compare, 'date');

export const getDateInUnit = (date, unit = 'date') => dayjs(date).get(unit);

export const isFirstDayOfTheMonth = (date, compare) => dayjs(date).startOf('month').isSame(compare);

export const isLastDayOfTheMonth = (date, compare) =>
  dayjs(date).endOf('month').isSame(compare, 'date');

export const getDaysInMonth = (date) => {
  const startWeek = dayjs(date).startOf('month').startOf('week');
  const endWeek = dayjs(date).endOf('month').endOf('week');
  const days = [];

  for (let curr = startWeek; curr.isBefore(endWeek); ) {
    days.push(curr.toDate());
    curr = curr.add(1, 'day');
  }

  return days;
};

export const getMonthsWithinRange = (start, end, count = 2) => {
  const months = [];

  for (let curr = start; curr.isBefore(end); ) {
    months.push(curr.toDate());
    curr = curr.add(count, 'month');
  }

  return months;
};
