import api from 'utils/api';

export const getProfile = async () => {
  const { data } = await api.get('/profile');

  return data;
};

export const updateProfile = async (data) => {
  const req = await api.patch('/profile/details', data).then(({ data }) => data.data);

  return req;
};

export const verifyEmail = async (data) => {
  const req = await api.post('/profile/email/verify', data).then(({ data }) => data.data);

  return req;
};

export const updatePassword = async (data) => {
  const req = await api.patch('/profile/change-password', data).then(({ data }) => data.data);

  return req;
};
