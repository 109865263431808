import { createSlice } from '@reduxjs/toolkit';
import {
  getAvailableDates,
  getDailyPerformance,
  getGraphPerformance,
  getMonthlyPerformance,
  getTopSalesProducts,
  getTopTrendingProducts,
  getTopWorstProducts,
  getWeeklyPerformance,
} from './actions';

const initialState = {
  monthlyPerformance: {
    error: '',
    isLoading: false,
    data: undefined,
  },
  weeklyPerformance: {
    error: '',
    isLoading: false,
    data: undefined,
  },
  dailyPerformance: {
    error: '',
    isLoading: false,
    data: undefined,
  },
  performanceGraph: {
    error: '',
    isLoading: false,
    data: undefined,
  },
  topSalesProducts: {
    error: '',
    isLoading: false,
    data: undefined,
  },
  topTrendingProducts: {
    error: '',
    isLoading: false,
    data: undefined,
  },
  topWorstProducts: {
    error: '',
    isLoading: false,
    data: undefined,
  },
  availableDates: {
    monthly: [],
    weekly: [],
    daily: null,
    isLoading: false,
  },
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // Get Available Dates
    builder.addCase(getAvailableDates.pending, (state) => {
      state.availableDates.isLoading = true;
      state.availableDates.error = '';
      state.availableDates.monthly = [];
      state.availableDates.weekly = [];
      state.availableDates.daily = null;
    });
    builder.addCase(getAvailableDates.fulfilled, (state, action) => {
      state.availableDates.isLoading = false;
      state.availableDates.monthly = action.payload?.data?.monthly;
      state.availableDates.weekly = action.payload?.data?.weekly;
      state.availableDates.daily = action.payload?.data?.daily;
    });
    builder.addCase(getAvailableDates.rejected, (state, action) => {
      state.availableDates.isLoading = false;
      state.availableDates.error = action.payload;
      state.availableDates.monthly = [];
      state.availableDates.weekly = [];
      state.availableDates.daily = null;
    });

    // Get Daily Performance
    builder.addCase(getDailyPerformance.pending, (state) => {
      state.dailyPerformance.data = undefined;
      state.dailyPerformance.isLoading = true;
      state.dailyPerformance.error = '';
    });
    builder.addCase(getDailyPerformance.fulfilled, (state, action) => {
      state.dailyPerformance.isLoading = false;
      state.dailyPerformance.data = action.payload.data;
    });
    builder.addCase(getDailyPerformance.rejected, (state, action) => {
      state.dailyPerformance.isLoading = false;
      state.dailyPerformance.data = undefined;
      state.dailyPerformance.error = action.payload;
    });

    // Get Weekly Performance
    builder.addCase(getWeeklyPerformance.pending, (state) => {
      state.weeklyPerformance.data = undefined;
      state.weeklyPerformance.isLoading = true;
      state.weeklyPerformance.error = '';
    });
    builder.addCase(getWeeklyPerformance.fulfilled, (state, action) => {
      state.weeklyPerformance.isLoading = false;
      state.weeklyPerformance.data = action.payload.data;
    });
    builder.addCase(getWeeklyPerformance.rejected, (state, action) => {
      state.weeklyPerformance.data = undefined;
      state.weeklyPerformance.isLoading = false;
      state.weeklyPerformance.error = action.payload;
    });

    // Get Monthly Performance
    builder.addCase(getMonthlyPerformance.pending, (state) => {
      state.monthlyPerformance.data = undefined;
      state.monthlyPerformance.isLoading = true;
      state.monthlyPerformance.error = '';
    });
    builder.addCase(getMonthlyPerformance.fulfilled, (state, action) => {
      state.monthlyPerformance.isLoading = false;
      state.monthlyPerformance.data = action.payload.data;
    });
    builder.addCase(getMonthlyPerformance.rejected, (state, action) => {
      state.monthlyPerformance.data = undefined;
      state.monthlyPerformance.isLoading = false;
      state.monthlyPerformance.error = action.payload;
    });

    // Get Graph Data
    builder.addCase(getGraphPerformance.pending, (state) => {
      state.performanceGraph.data = undefined;
      state.performanceGraph.isLoading = true;
      state.performanceGraph.error = '';
    });
    builder.addCase(getGraphPerformance.fulfilled, (state, action) => {
      state.performanceGraph.isLoading = false;
      state.performanceGraph.data = action.payload.data;
    });
    builder.addCase(getGraphPerformance.rejected, (state, action) => {
      state.performanceGraph.data = undefined;
      state.performanceGraph.isLoading = false;
      state.performanceGraph.error = action.payload;
    });

    // Get Top Sales Products
    builder.addCase(getTopSalesProducts.pending, (state) => {
      state.topSalesProducts.data = undefined;
      state.topSalesProducts.isLoading = true;
      state.topSalesProducts.error = '';
    });
    builder.addCase(getTopSalesProducts.fulfilled, (state, action) => {
      state.topSalesProducts.isLoading = false;
      state.topSalesProducts.data = action.payload.data;
    });
    builder.addCase(getTopSalesProducts.rejected, (state, action) => {
      state.topSalesProducts.data = undefined;
      state.topSalesProducts.isLoading = false;
      state.topSalesProducts.error = action.payload;
    });

    // Get Top Trending Products
    builder.addCase(getTopTrendingProducts.pending, (state) => {
      state.topTrendingProducts.isLoading = true;
      state.topTrendingProducts.error = '';
    });
    builder.addCase(getTopTrendingProducts.fulfilled, (state, action) => {
      state.topTrendingProducts.data = undefined;
      state.topTrendingProducts.isLoading = false;
      state.topTrendingProducts.data = action.payload.data;
    });
    builder.addCase(getTopTrendingProducts.rejected, (state, action) => {
      state.topTrendingProducts.data = undefined;
      state.topTrendingProducts.isLoading = false;
      state.topTrendingProducts.error = action.payload;
    });

    // Get Top Wordst products
    builder.addCase(getTopWorstProducts.pending, (state) => {
      state.topWorstProducts.isLoading = true;
      state.topWorstProducts.error = '';
    });
    builder.addCase(getTopWorstProducts.fulfilled, (state, action) => {
      state.topWorstProducts.data = undefined;
      state.topWorstProducts.isLoading = false;
      state.topWorstProducts.data = action.payload.data;
    });
    builder.addCase(getTopWorstProducts.rejected, (state, action) => {
      state.topWorstProducts.data = undefined;
      state.topWorstProducts.isLoading = false;
      state.topWorstProducts.error = action.payload;
    });
  },
});

export default dashboardSlice.reducer;

export const actions = {
  ...dashboardSlice.actions,
  getAvailableDates,
  getDailyPerformance,
  getMonthlyPerformance,
  getWeeklyPerformance,
  getGraphPerformance,
  getTopSalesProducts,
  getTopTrendingProducts,
  getTopWorstProducts,
};

export * as selectors from './selectors';
