import api from 'utils/api';

export const login = async (payload) => {
  const { data } = await api.post('/oauth/token', {
    ...payload,
    grant_type: 'password',
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
  });

  return data;
};

export const logout = async () => api.delete('/oauth/token');

export const forgotPassword = async (data) => api.post('/password/forgot', data);

export const resetPassword = async (data) => api.post('/password/reset', data);

export const registerPassword = async (data) => api.post('/password/register', data);
