import { createSelector } from '@reduxjs/toolkit';

const rootSelector = createSelector(
  (state) => state.analysisByChannel,
  (analysis) => analysis
);

export const availableDatesSelector = createSelector(
  rootSelector,
  (analysis) => analysis.availableDates
);

export const productGroupsSelector = createSelector(
  rootSelector,
  (analysis) => analysis.productGroups
);

export const topSalesProductsSelector = createSelector(
  rootSelector,
  (analysis) => analysis.topSalesProducts
);

export const topTrendingProductsSelector = createSelector(
  rootSelector,
  (analysis) => analysis.topTrendingProducts
);

export const topWorstProductsSelector = createSelector(
  rootSelector,
  (analysis) => analysis.topWorstProducts
);

export const analysisSelector = createSelector(rootSelector, (analysis) => analysis.analysis);
